.background-black {
    .subtitle-and-link {
        .content {
            border-color: $white;
            a {
                color: $white;
            }
        }
    }
}
.subtitle-and-link {
    .content {
        padding-bottom: 8px;
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid $black;
        width: 100%;
        
        p {
            @include font-size(20, 44, 400, 5);
            text-transform: uppercase;
        }

        @include device-md {
            flex-direction: column;
            align-items: flex-start;
            border: none;

            p {
                border-bottom: 2px solid $black;
                width: 100%;
            }

            a {
                margin-top: 8px;
            }
        }
    }
}