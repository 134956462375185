.message {
    max-width: $max_width_wrapper;

    @include main-grid();

    margin: 0 auto;

    padding: 5rem 1.5rem;

    @include device-lg("min") {
        padding: 0;
    }

    &__content {
        grid-column: 1/8;

        @include device-md("min") {
            grid-column: 1/12;
        }

        @include device-lg("min") {
            grid-column: 3/12;
        }

        h1,
        p {
            text-align: center;

            @include device-lg("min") {
                text-align: left;
            }
        }

        h1 {
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 2.5rem;

            @include device-lg("min") {
                margin-bottom: 5rem;
            }
        }
    }

    &__image {
        display: none;

        @include device-lg("min") {
            display: block;

            grid-column: 15/24;
        }

        img {
            display: block;

            width: 100%;
            height: 100%;
        }
    }
}
