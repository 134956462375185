.legal-footer {
    padding: 40px 0;
    p {
        font-size: 16px;
        color: $gray;
        b {
            color: $gray-700;
            font-weight: 700;
        }
    }
    a {
        text-decoration: underline;
        color: $gray-700;
    }
}