.background-black .box-with-background {
    &_category {
        color: $white;
        border-bottom: solid 2px $white;
    }
}
.box-with-background {
    padding-top: 24px;
    padding-bottom: 16px;

    // This class is only used in the store
    &.store-padding {
        padding-bottom: 80px;
    }

    &_img {
        width: 100%;
        height: 373px;
        object-fit: cover;
        margin-bottom: 17px;
        padding-left: 80px;
        padding-right: 80px;
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
        display: block;

        @include device-lg {
            padding-left: 32px;
            padding-right: 32px;
        }
        @include device-md {
            padding: 0;
        }

        @include rwd(800) {
            height: 464px;
        }
    }

    &_category {
        width: 100%;
        color: $black;
        @include font-size(20, 44, 300, 5);
        border-bottom: solid 2px $black;
        margin-bottom: 30px;
        padding-bottom: 10px;
    }

    &_container {
        // Store
        &.box-flex-store {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            @include device-md("min") {
                flex-direction: row;
            }

            .store-width {
                width: 100%;
            }
        }

        // Website
        &.box-flex {
            display: flex;
            justify-content: center;
            gap: 16px;
            flex-wrap: wrap;
        }

        &.box-grid {
            gap: 16px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(327px, 1fr));
            grid-template-rows: auto;
            justify-items: center;
            // place-content: center;
        }

        &_card {
            position: relative;
            height: max-content;
            display: flex;
            flex-direction: column;
            padding: 1.5rem;
            row-gap: 25px;
            min-width: 327px;
            max-width: 416px;
            // background-color: #f7f7f7;
            background-color: $white;

            &.store-width {
                min-width: 0;
                max-width: none;
            }

            // Only of the card is type mantencion
            &.--mantencion {
                background-color: #b9c0c2;

                .box-with-background_card-subtitle {
                    font-style: italic;
                }
            }

            &.--merchandise {
                height: 100%;
                .box-with-background_container_card-top_img {
                    aspect-ratio: 4 / 3;
                    object-fit: contain;
                    max-height: 132px;
                }
            }

            &.--out-of-stock {
                .box-with-background_card-title,
                .box-with-background_card-subtitle,
                .box-with-background_card-price {
                    color: $gray-700;
                }

                img {
                    filter: opacity(75%);
                }
            }

            &.--out-of-stock:not(.--mantencion) {
                background-color: #eeeeef;
            }

            &-top {
                display: flex;
                flex-direction: row;

                &_text {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    color: $black !important;
                    // margin-right: 24px;
                    // margin-left: 24px;

                    .left {
                        width: 57%;

                        overflow: hidden;
                    }

                    .right {
                        width: max-content;
                        max-width: 60%;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        // justify-content: space-between;
                    }

                    .box-with-background_card-subtitle,
                    .box-with-background_card-price,
                    .box-with-background_card-km {
                        letter-spacing: 0.05em;
                    }

                    .box-with-background_card-price {
                        width: max-content;
                        margin-left: auto;
                    }

                    .box-with-background_card-title,
                    .box-with-background_card-price {
                        text-transform: uppercase;
                    }

                    .box-with-background_card-title {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .box-with-background_card-green-tax {
                        @include font-size(14, 16, 400);
                        color: $gray;
                    }
                }

                &_icon {
                    // width: 34px;
                    // height: 100%;
                    margin-right: 14px;
                }

                &_img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &_details {
            margin-top: 24px;
            min-width: 327px;
            max-width: 416px;

            &-text {
                p {
                    font-weight: 300 !important;
                    line-height: 2;
                }
            }

            .--text-l {
                margin-bottom: 16px;
            }
        }

        @include rwd(1024) {
            flex-wrap: wrap;

            &_details {
                border-bottom: 2px solid $gray-200;
                padding-bottom: 40px;
                margin-bottom: 24px;
            }
        }
    }

    &_disclaimer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-top: 22px;
        p {
            @include font-size(14, 18, 400, 5);
        }
        a {
            text-decoration: underline;
        }
    }

    @include rwd(800) {
        padding-bottom: 40px;
    }

    &__overlay {
        position: absolute;

        top: 0;
        left: 0;

        background-color: rgba($white, 0.4);

        width: 100%;
        height: 100%;
    }
}

.box-with-background__store-version {
    grid-column: 1/8;

    align-self: start;

    @include device-md("min") {
        grid-column: 6/12;
    }

    @include device-lg("min") {
        grid-column: 8/24;
    }

    .box-with-background_container {
        width: 100%;

        display: grid;
        gap: 20px;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        grid-auto-flow: row;

        @include device-lg("min") {
            grid-template-columns: repeat(2, 1fr);
        }

        .content {
            position: relative;
            height: 100%;
        }

        a {
            display: block;
            height: 100%;
        }

        .box-with-background_container_card {
            // background-color: rgba(green, 0.5);
            height: 100%;
            min-width: auto;

            .badge-card {
                z-index: 2;
            }
        }
    }

    .paginator {
        background-color: rgba(blue, 0.5);

        margin-top: 16px;
    }
}
