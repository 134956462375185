.title-and-subtitle {
    margin-top: 2rem;
    margin-bottom: 5rem;

    @include device-lg("min") {
        margin-top: 5rem;
    }

    .subtitle {
        @include font-size(20, 30, 400);

        text-transform: uppercase;

        // TODO: esto en el figma lo veo mas fino.
        // para mi deberia ir un weight un poco mas finito.
        // Preguntar con disenio
        // font-weight: 300;
    }
}
