.fixed-bar {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;

    &_container {
        background-color: $black;
        opacity: 0.9;
        margin-right: 20px;
        margin-left: 20px;
        padding: 20px;

        &_content {
            display: flex;
            justify-content: center;
            align-items: center;

            .first, .second {
                display: flex;
                align-items: center;
                padding-right: 44px;
                border-right: 1px solid $gray-600;
                
                svg {
                    margin-right: 20px;
                }
                
                a {
                    color: $white;
                    text-decoration: underline;
                    @include font-size(18, 16.38, 400, 3)
                }
            }
            
            .second {
                padding-left: 44px;
            }

            .btn-large {
                margin-left: 40px;
            }
        }
    }

    @include rwd(928) {
        display: none;
    }
}