.product-images-slider {
    width: 100%;

    .swiper-slide {
        padding-top: 70%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            object-fit: contain;
        }
    }

    .swiper-button-prev {
        left: 30;
        color: #000;
    }

    .swiper-button-next {
        right: 30;
        color: #000;
    }
}

.product-images-slider-thumbs {
    width: 100%;

    margin-top: 1rem;

    .swiper-slide {
        cursor: pointer;
        border: 1px solid #dddbdb;

        &-thumb-active {
            border-color: #000;
        }
    }

    &-wrapper {
        width: 100%;
        padding-top: 65%;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            object-fit: contain;
        }
    }
}
