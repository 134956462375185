.filter-container {
    &__title {
        @include font-size(14, 20, 700);

        color: $gray-700;

        margin-bottom: 0.875rem;
    }

    .input {
        margin-bottom: 0.4rem;

        &__field {
            border: none;
        }
    }
}
