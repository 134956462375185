.paragraph-img {
    padding-top: 60px;
    padding-bottom: 60px;
    overflow: hidden;
    
    p {
        margin-bottom: 20px;
    }
    
    &_container {
        @include main-grid;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 744px;
        
        &_text {
            grid-column: 1 / span 9;
            width: 469px;
            
            h3 {
                margin-bottom: 24px;
                text-transform: uppercase;
            }
        }

        &_img {
            grid-column: 11 / -1
        }

        .bg_img_mobile {
            display: none;
        }
    }

    @include rwd(800) {
        padding-top: 30px;
        padding-bottom: 30px;

        &_container {
            display: flex;
            flex-direction: column-reverse;
            background-image: none !important;

            &_text {
                width: 100%;

                h3 {
                    margin-top: 40px;
                }
            }

            &_img {
                width: 100%;
            }

            .bg_img_mobile {
                display: block;
                width: 100%;
            }
        }
    }
}