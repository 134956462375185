.banner_pre_footer {
    height: 353px;
    background-color: $black;
    display: block;
    
    &_content {
        background-size: cover;
        background-position: center;
        color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
        
        &_text {
            width: 390px;

            h1 {
                margin-bottom: 24px;
            }

            p {
                padding-top: 24px;
                border-top: 1px solid $white;
                margin-bottom: 24px;
                width: 100%;
                font-weight: 300;
            }
        }
    }

    @include device-md {
        &_content {
            &_text {
                width: 100%;
            }
        }
    }
}