.financiacion {
    h1 {
        margin-bottom: 1rem;
    }

    .main-subtitle {
        font-size: 20px;
        font-weight: 300;

        text-transform: uppercase;
    }

    .big-photo-and-description_element {
        height: 640px;
    }

    .big-photo-and-description_element-text {
        width: 272px;
        .title {
            font-size: 26px;
        }

        .subtitle {
            font-size: 30px;
        }
    }

    &__plan {
        margin-bottom: 2.5rem;

        @include device-lg("min") {
            margin-bottom: 5rem;
        }
    }

    // Override accordion__item styles
    .accordion__item {
        margin-bottom: 0;
    }
}
