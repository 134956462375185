.title-and-button {
    padding-top: 62px;
    padding-bottom: 20px;

    &.store-padding {
        padding-top: 3.375rem;
        padding-bottom: 3.375rem;
    }

    .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;

        span {
            display: flex;
            gap: 16px;
        }

        .text {
            display: flex;
            flex-direction: column;

            margin-bottom: 1rem;

            @include device-md("min") {
                margin-bottom: 0;
            }

            .title,
            strong {
                @include font-size(40, 44, 400, 5);
                text-transform: uppercase;

                @include device-md {
                    @include font-size(32, 40, 400, 5);
                }

                p {
                    @include font-size(40, 44, 400, 5);
                    text-transform: uppercase;

                    @include device-md {
                        @include font-size(32, 40, 400, 5);
                    }
                }
            }

            .title2 {
                font-weight: 300;
            }
        }

        @include rwd(800) {
            flex-direction: column;

            .title {
                margin-bottom: 8px;
            }

            span {
                flex-direction: column;
                width: 100%;
                gap: 8px;
            }
        }
    }

    @include rwd(800) {
        padding-top: 40px;
    }
}
