.versions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 96px;

    &_item {
        display: flex;
        flex-direction: column;
        width: 90%;

        &_text {
            .--text-m {
                text-transform: uppercase;
            }

            .green-tax {
                @include font-size(12, 18, 400);
                color: $gray;
            }

            .disclaimer {
                color: $gray;
                min-height: 18px;
            }
        }
        
        &_img {
            margin: 14px 0px;
            border-left: 1px solid $black;
            padding-left: 55px;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    &_item:nth-child(even) {
        justify-self: end;
    }
    
    &_item:nth-child(odd) {
        justify-self: start;
    }

    @include rwd(800) {
        grid-template-columns: 1fr;
        margin-bottom: 46px;

        &_item:nth-child(even), &_item:nth-child(odd) {
            justify-self: center;
            width: 100%;
        }
    }
}