.banner-swiper {
    margin-top: 0px !important;
    background-color: $black;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 23px;
    
    .banner-swiper__sliders__slider {
        max-width: 1440px;
        position: relative;
        display: flex;
        justify-content: center;

        &-content {
            position: absolute;
            inset: 0 0 auto 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            user-select: none;
            display: none;
            pointer-events: none;

            .title-and-button {
                span {
                    pointer-events: all;
                }
            }

            &.--show {
                display: block;
            }
        }

        &::before {
            content: '';
            position: absolute;
            inset: 0 auto 0 0;
            z-index: 9998;
            width: 43vw;
            background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
            pointer-events: none;
        }
        
        &::after {
            content: '';
            position: absolute;
            inset: 0 0 0 auto;
            z-index: 9998;
            width: 10vw;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
            pointer-events: none;
        }

        &.no-gradient {
            &::before, 
            &::after {
                display: none;
            }
        }
        
        @include rwd(800) {
            &::before, 
            &::after {
                display: none;
            }
        }
    }

    .swiper-slide {
        min-height: calc(100vh - 133px);
        height: 750px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $black;
        position: relative;

        .shadow, .block {
            display: none;
        }

        .wrapper {
            height: 100%;
        }

        @include device-xl(min) {
            min-height: 100%;
        }

        .title-and-button {
            .wrapper {    
                .text {
                    .title {
                        p {
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    .swiper-pagination, .swiper-pagination-up, .swiper-pagination-down {
        text-align: left;
        position: absolute;
        display: flex;
        bottom: 84px;
        z-index: 9999;
        width: 100%;
        justify-content: space-between;
        gap: 15px;
        color: $white;

        &-bullet {
            position: relative;
            width: 100%;
            height: 2px;
            background-color: rgba($white, .3);
            cursor: pointer;
            transition: background-color $timming ease-in-out, opacity $timming ease-in-out;
            display: block;

            &::after {
                content: '';
                position: absolute;
                inset: 0 auto 0 0;
                width: 0%;
                background-color: $white;
                transition: width 3.8s ease-out;
                opacity: 0;
            }

            &-active {
                &::after {
                    opacity: 1;
                    width: 100%;
                }
            }
        }
    }

    .swiper-pagination-up {
        bottom: 93px;

        span {
            width: 100%;
            @include font-size(10, 12, 700, 10);
        }
    }

    .swiper-pagination-down {
        bottom: 56px;

        span {
            width: 100%;
            @include font-size(16, 19, 400, 5);
            text-transform: uppercase;
        }
    }

    .title-and-button {
        color: $white;
        h1 {
            font-weight: 300;
        }
    }

    .corporate_home_hero__sliders__slider {
        padding-top: 0;
    }

    .banner-swiper__background {
        position: absolute;
        inset: 0;
        background-size: cover;
        background-position: center;
    }
    
    .banner-swiper__background.mobile {
        display: none;
    }

    .background-container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    
    &-black-bottom {
        .swiper-slide {
            background-color: #191919;
            padding-bottom: 97px;
            min-height: auto;
            height: auto;

            .background-container {
                max-height: 623px;
                .banner-swiper__background {
                    aspect-ratio: 1440 / 623;
                    position: relative;
                    background-position: top;
                }
            }
        }
        .swiper-pagination-up {
            bottom: 63px;
        }
        .swiper-pagination-horizontal {
            bottom: 54px;
        }
        .swiper-pagination-down {
            bottom: 26px;
        }
    }
    
    @media (max-width: 800px) {
        .banner-swiper__background.desktop {
            display: none;
        }
    
        .banner-swiper__background.mobile {
            display: block;
        }
    }    

    @include rwd(800) {
        .swiper-slide {
            height: 387px;
            min-height: auto;

            .shadow, .block {
                display: block;
                height: 66px;
                width: 100vw;
                position: absolute;
                left: 0;
            }

            .shadow {
                top: 66px;
                background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 88.79%);

                &.new-banner {
                    display: none;
                }
            }
            
            .block {
                background-color: $black;

                &.new-banner {
                    display: none;
                }
            }
        }

        .swiper-pagination, .swiper-pagination-up, .swiper-pagination-down {
            display: none;
        }

        .title-and-button {
            height: 100%;
            padding-bottom: 22px;
            padding-top: 32px;
            position: relative;
            z-index: 2;

            span {
                flex-direction: row;
            }
        }

        .btn-large {
            padding: 16px 9px;
            
        }

        &-black-bottom {
            .swiper-slide {
                padding-bottom: 0;
                height: auto;
                min-height: auto;
            }
        }
    }
}