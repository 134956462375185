.background-black .list-items {
    background-color: $black;
}

.list-items {
    margin-top: 40px;
    margin-bottom: 40px;

    &_container {
        padding: 40px 40px 50px 40px;
        background-color: $gray-25;
    
        h3 {
            text-transform: uppercase;
            padding-bottom: 16px;
            margin-bottom: 24px;
            border-bottom: 1px solid $gray-600;
        }
    
        p {
            margin-bottom: 0;
        }
    
        li {
            list-style: disc;
            margin-left: 24px;
            margin-bottom: 10px;
            padding-left: 5px;
        }
    }
}