.background-black .title_subtitle_series {
    .series-grid_container_card-title {
        color: $white;
    }
}
.title_subtitle_series {
    padding-top: 60px;
    padding-bottom: 60px;

    h1 {
        margin-bottom: 10px;
    }


    .series-grid {
        padding-top: 40px;
        padding-bottom: 65px;
        display: grid;
        gap: 22px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        
        @include rwd(800) {
            display: flex;
            flex-direction: column;
        }
        
        &_container_card {
            height: max-content;
            
            &-title {
                @include font-size(20, 44, 400, 5);
                color: $black;
                margin-bottom: 7px;
                text-transform: uppercase;
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
        }
    }
}