.forms-flotas {
    background-color: $white;

    padding-bottom: 5rem;

    form {
        // display: grid;

        // grid-template-columns: 100%;

        display: flex;

        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;

        gap: 2.5rem;

        @include device-md("min") {
            flex-direction: row;

            margin-bottom: 5rem;
        }

        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }

    .left-container {
        display: flex;

        flex-direction: column;

        gap: 1rem;

        @include device-md("min") {
            width: 45%;
        }
    }

    .right-container {
        @include device-md("min") {
            width: 45%;
        }
    }

    h1 {
        padding-top: 2.8rem;
        margin-bottom: 1rem;
    }

    & .wrapper > p {
        max-width: 31.25rem;

        margin-bottom: 2.5rem;
    }

    fieldset {
        display: flex;

        flex-direction: column;

        margin-bottom: 2.5rem;

        @include device-md("min") {
            margin-bottom: 0;
        }

        h4 {
            grid-column: 1 / 3;

            margin-bottom: 1.5rem;

            border-bottom: 1px $black solid;
        }
    }

    .ego-form__field {
        margin-bottom: 0.5rem;

        input {
            margin-bottom: 0.5rem;
        }

        &.--select {
            border: solid 1px $gray-200;

            .value-placeholder {
                // max-width: 60px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__error {
            margin: 0;
        }
    }

    .input-group {
        display: flex;

        gap: 0.5rem;

        @include device-md("min") {
            flex: 0 1 auto;
        }
    }

    .personal-data {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .company-data {
        grid-column: 1/2;
    }

    .choose-your-cars {
        &__controls {
            display: flex;

            gap: 0.5rem;

            & > div {
                max-width: 30%;
            }

            .btn-add {
                margin-top: 11px;
                cursor: pointer;
            }
        }

        .max-quantity {
            @include font-size(12, 16, 400);
        }

        .ego-form__field {
            margin-bottom: 0;

            &.--select {
                border: solid 1px $gray-200;
            }

            &.--message {
                margin-top: 2rem;
            }
        }

        &__results {
            margin-top: 1rem;

            thead {
                border-bottom: 1px $gray-600 solid;
            }

            th {
                padding-bottom: 0.25rem;
            }

            tr {
                @include font-size(14, 20, 400);

                text-align: left;
            }

            tbody {
                text-transform: uppercase;

                color: $gray-600;

                td {
                    padding-top: 0.75rem;

                    @include font-size(14, 20, 400);

                    & > span {
                        display: flex;
                        justify-content: space-between;
                    }

                    img {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .legal-terms {
        margin-top: 1rem;

        @include font-size(14, 16, 400);

        color: $gray-600;

        text-align: right;
    }

    .submit {
        margin-left: auto;
        margin-top: 2.5rem;
    }
}
