.description {
    margin-top: 8.75rem;

    &__title {
        @include font-size(14, 20, 700);

        text-transform: uppercase;

        margin-bottom: 1rem;
    }

    ul {
        list-style-type: disc;

        margin-left: 2rem;
    }

    li,
    p {
        @include font-size(16, 24, 400);

        margin-bottom: 1.5rem;

        // text-transform: uppercase;
    }

    &.--car {
        display: grid;
        grid-template-columns: 1fr;

        gap: 1.25rem;

        margin-top: 1.5rem;
        margin-bottom: 3rem;

        @include device-lg("min") {
            width: 80%;
            margin-top: 2rem;
            margin-bottom: 5rem;
            grid-template-columns: 1fr 1fr;
        }

        h4 {
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;
        }

        .description__box {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: 0.75rem;

            div {
                display: flex;
                align-items: center;
            }
        }
    }

    &__box {
        display: flex;

        flex-direction: column;

        // gap: 0.75rem;

        @include device-lg("min") {
            flex-direction: column-reverse;
        }
    }
}
