.columns {
    background-color: $white;

    margin-bottom: 2.5rem;

    @include device-lg("min") {
        margin-bottom: 5rem;
    }

    &__content {
        display: grid;

        gap: 2rem;

        &.--3-columns {
            @include device-lg("min") {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }

    // &__container {
    //     // To center the box in the container
    //     max-width: 300px;
    // }

    &__box {
        @include device-lg("min") {
            &:nth-child(2) {
                .columns__box__container {
                    margin: 0 auto;
                }
            }

            &:nth-child(3) {
                .columns__box__container {
                    margin-left: auto;
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 2px #eeeeef solid;

            @include device-lg("min") {
                border-bottom: none;

                border-right: 2px #eeeeef solid;
            }
        }

        &__container {
            @include device-lg("min") {
                // To center the box in the container
                max-width: 300px;

                &:nth-child(2) {
                    margin: 0 auto;
                }

                &:nth-child(3) {
                    margin-left: auto;
                }
            }
        }

        &__title {
            @include font-size(20, 44, 700);

            font-weight: 700;

            margin-bottom: 0.5rem;

            text-transform: uppercase;
        }

        &__description {
            @include font-size(16, 24, 400);
        }
    }
}
