.box-with-border {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding: 1rem;
    // margin-bottom: 2.5rem;

    border: 2px $black solid;

    // Styles for the counter
    &.counter {
        .decrement,
        .increment {
            display: flex;

            cursor: pointer;
        }

        &.--cart {
            border: 1px #eeeeef solid;

            padding: 0.5rem;

            gap: 1rem;
        }
    }

    &__label,
    &__price {
        font-size: 0.875rem;

        text-transform: uppercase;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &.--cart-totals {
        margin-bottom: 1rem;

        flex-direction: column;
        align-items: stretch;

        .box-with-border__container {
            display: flex;

            justify-content: space-between;
            align-items: center;

            margin-bottom: 1rem;
        }

        .line {
            width: 100%;
            height: 1px;

            background-color: #b9c0c2;

            margin-bottom: 1.125rem;
        }

        .box-with-border__price {
            flex-shrink: 0;
        }

        .subtotal {
            margin: 0;
        }
    }
}
