.background-black .big-photo-and-description {
    &_element-text {
        .line {
            border-color: $white;
        }
    }
}
.big-photo-and-description {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    &_element {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 45px;
        position: relative;
        min-height: 495px;
        overflow-x: hidden;

        .img-container {
            height: 495px;
            // width: 1055px;
            width: calc(100% - 352px);
            object-fit: cover;
            position: absolute;

            background-repeat: no-repeat;
            background-size: cover;
            min-height: 426px;

            &.--with-button {
                width: 911px;
            }
        }

        &-img__mobile {
            display: none;
        }

        &-text {
            display: flex;
            flex-direction: column;
            width: 200px;

            @include rwd(1024) {
                width: 260px;
            }

            .title {
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            .line {
                border-left: 1px solid #000;
                height: 70px;
            }

            .description {
                margin-top: 16px;
                margin-bottom: 24px;
            }

            &.--with-button {
                width: 360px;
            }
        }

        .wrapper {
            padding-top: 0;
        }
    }

    &.right {
        .big-photo-and-description_element:nth-child(odd) {
            flex-direction: row-reverse;

            .img-container {
                left: 385px;

                &.--with-button {
                    left: 529px;
                }
            }
        }

        .big-photo-and-description_element:nth-child(even) {
            flex-direction: row;

            .wrapper {
                display: flex;
                justify-content: flex-end;
            }

            .img-container {
                right: 385px;

                &.--with-button {
                    right: 529px;
                }
            }
        }
    }

    &.left {
        .big-photo-and-description_element:nth-child(even) {
            flex-direction: row-reverse;

            .img-container {
                left: 385px;

                &.--with-button {
                    left: 529px;
                }
            }
        }

        .big-photo-and-description_element:nth-child(odd) {
            flex-direction: row;

            .wrapper {
                display: flex;
                justify-content: flex-end;
            }

            .img-container {
                right: 385px;

                &.--with-button {
                    right: 529px;
                }
            }
        }
    }

    &.left,
    &.right {
        @include rwd(1024) {
            .big-photo-and-description_element {
                .img-container {
                    width: calc(100% - 300px);
                }
            }
        }

        @include rwd(800) {
            .big-photo-and-description_element-img__mobile {
                display: block;
                width: 100%;
            }

            .img-container {
                display: none;
            }

            .big-photo-and-description_element {
                flex-direction: column;
                height: auto;
                justify-content: center;
                margin-bottom: 24px;

                &-img {
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                    position: inherit;
                }

                &-text {
                    width: 100%;
                    margin-top: 24px;
                }
            }

            .big-photo-and-description_element:nth-child(odd) {
                flex-direction: column;

                .big-photo-and-description_element-img {
                    left: auto;
                    right: auto;

                    &.--with-button {
                        left: auto;
                    }
                }
            }

            .big-photo-and-description_element:nth-child(even) {
                flex-direction: column;

                .big-photo-and-description_element-img {
                    right: auto;
                    left: auto;

                    &.--with-button {
                        left: auto;
                    }
                }
            }
        }
        // This styles applies only in the store pages
        // or you can use .--store-version modifier to use this styles
        &.--store-version {
            .big-photo-and-description_element {
                margin-bottom: 2.5rem;

                @include device-lg("min") {
                    margin-bottom: 5rem;
                }

                .title {
                    margin-bottom: 0;
                }

                .subtitle {
                    text-transform: uppercase;

                    margin-bottom: 1rem;
                }
            }

            @include rwd(800) {
                .big-photo-and-description_element {
                    flex-direction: column-reverse;
                }

                .big-photo-and-description_element-img {
                    padding: 0 1rem;
                }
            }
        }
    }
}
