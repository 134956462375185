.ayuda {
    padding-top: 2rem;

    @include device-lg("min") {
        padding-top: 4rem;
    }

    h1 {
        // margin-top: 2rem;
        margin-bottom: 2.5rem;

        @include device-lg("min") {
            // margin-top: 4rem;
            margin-bottom: 4.5rem;
        }
    }

    p {
        margin-bottom: 1.5rem;

        b {
            font-weight: 600 !important;
        }
    }
}
