.drawer {
    position: fixed;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;

    flex-direction: column;

    justify-content: flex-end;

    z-index: 999;

    background-color: $black;

    display: none;
    // height: 0;

    &.--open {
        display: block;
        // height: 100%;

        &.--expanded {
            .drawer__box {
                height: 100vh;
            }
        }
    }

    // TODO: donde deberia ir esto?
    // pointer-events: none;

    &__overlay {
        display: flex;

        flex-direction: column;
        justify-content: flex-end;

        height: 100%;
    }

    .bar {
        height: 0.3215rem;

        background-color: $white;

        border-radius: 1rem;

        margin: 0 auto;

        &__container {
            width: 100%;

            padding-top: 1.375rem;
        }

        &-small {
            width: 3rem;

            margin-bottom: 1.375rem;

            background-color: #cdcfd0;
        }

        &-big {
            width: 9.25rem;

            background-color: $black;

            margin-top: auto;
            margin-bottom: 0;
        }
    }

    &__box {
        // height: 50vh;

        display: flex;
        flex-direction: column;

        background-color: $white;

        padding: 0.5rem 0;

        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        transition: height $timming ease-in-out;
    }

    &__header {
        display: flex;

        justify-content: space-between;
        align-items: center;

        margin-bottom: 2.5rem;

        padding: 0 1.5rem;

        text-transform: uppercase;

        h3 {
            font-size: 1.375rem;
        }
    }

    &__content {
        overflow: hidden;
    }

    &__scroll {
        overflow-y: scroll;

        // height: 100vh;
        // height: calc(100vh - 140px);
    }

    ul {
        li {
            padding: 1rem 1.5rem;
            cursor: pointer;
        }
    }

    .--drawer {
        padding: 1rem 1.5rem;

        label {
            text-transform: uppercase;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
