.map-branches-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .title-and-button {
        padding-top: 44px;
        padding-bottom: 44px;
        
        .btn-large {
            display: flex;
    
            svg {
                margin-right: 10px;
            }
        }
    }
    
    .map-content {
        position: relative;

        .cards-dealerships-container {
            position: absolute;
            top: 0;
            left: 100px;
            height: 100%;
            overflow-y: scroll;
            @include custom-scrollbars;

            @include device-lg {
                display: none;
            }

            .dealer-card {
                background-color: $white;
                padding: 38px 50px 16px 50px;
                width: 490px;
                margin: 40px 0px;
                text-align: left;
                cursor: pointer;
    
                .dealer-name {
                    text-transform: uppercase;
                    border-bottom: 1px solid $black;
                    margin-bottom: 28px;
                    padding-bottom: 4px;
                }
    
                p {
                    margin-bottom: 8px;
                    font-weight: 300;
                }
            }
        }

        .modal-dealership-details {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .modal-box-desktop, .modal-box-mobile {
                h4 {
                    text-transform: uppercase;
                    border-bottom: 1px solid $black;
                    margin-bottom: 24px;
                    padding-bottom: 8px;
                }

                .modal-close {
                    position: absolute;
                    right: 23px;
                    top: 40px;
                    cursor: pointer;
                }

                .modal-body-content {
                    margin-bottom: 48px;

                    span {
                        display: flex;
                        margin-bottom: 16px;

                        svg {
                            margin-right: 8px;
                        }
                    }

                    .btn-large {
                        margin-top: 16px;
                    }
                }

                .modal-body-content:last-of-type {
                    margin-bottom: 0;
                }
            }

            .modal-box-desktop {
                background-color: $white;
                margin-top: 60px;
                margin-left: 100px;
                padding: 40px 50px;
                width: 490px;
                position: relative;
            }

            .modal-box-mobile {
                display: none;
                background-color: $white;
                position: fixed;
                bottom: 0;
                width: 100vw;
                padding: 34px 24px 74px 24px;
                border-radius: 16px 16px 0px 0px;

                .modal-header {
                    display: flex;
                    justify-content: space-between;

                    .modal-close {
                        position: initial;
                        width: 24px;
                        margin-top: 5px;
                    }

                    h4 {
                        border: none;
                        margin-bottom: 38px;
                        strong {
                            font-weight: 400;
                        }
                    }
                }
            }

            .fw-300 {
                font-weight: 300;
            }
            
            @include device-lg {
                position: fixed;
                background: rgba(0,0,0,.5);
                z-index: 1000;

                .modal-box-desktop {
                    display: none;
                }

                .modal-box-mobile {
                    display: block;
                }
            }
        }
    }

}