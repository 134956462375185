.background-black .quote {
    &_container_text {
        h4 {
            border-color: $white;
        }
    }
}
.quote {
    padding-top: 60px;
    padding-bottom: 60px;

    &_container {
        @include main-grid;

        &_text {
            grid-column: 1 / span 9;

            h4 {
                padding-bottom: 24px;
                border-bottom: 1px solid $black;
                margin-bottom: 24px;
                text-transform: uppercase;
            }

            p {
                font-weight: 300;
            }
        }

        img {
            grid-column: 18 / -1;
            justify-self: end;
        }
    }

    @include rwd(800) {
        padding-top: 30px;
        padding-bottom: 30px;

        &_container {
            display: flex;
            flex-direction: column-reverse;

            &_text {
                h4 {
                    margin-top: 40px;
                }
            }
        }
    }
}