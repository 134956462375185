.banner_black_box-somos-lexus {
    color: $white;
    padding-top: 40px;
    padding-bottom: 40px;

    .banner_black_box_container {
        position: relative;
        height: 705px;
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        &_content {
            width: max-content;
            opacity: 0.8;
            background-color: $black;
            color: $white;
            display: flex;
            flex-direction: column;
            padding: 50px 80px 50px 50px;
    
            h3 {
                margin-bottom: 24px;
                width: 418px;
                text-transform: uppercase;
            }

            p {
                padding-top: 24px;
                border-top: 1px solid $white;
                margin-bottom: 0;
                width: 100%;
                font-weight: 300;
                width: 418px;
            }
        }
    }

    @include device-md {
        padding-top: 0;
        padding-bottom: 0;

        .banner_black_box_container {
            height: max-content;

            .wrapper {
                padding-left: 0;
            }
    
            &_content {
                width: 100vw;
                height: 100%;
                padding: 60px 24px 52px 24px;

                h3, p {
                    width: 100%;
                }
            }
        }
    }
}