.maintenance_page {
    h3 {
        text-transform: uppercase;
        margin-top: 64px;
        margin-bottom: 107px;
    }

    .maintenance_page_top {
        @include main-grid;

        .selects_container {
            grid-column: 1 / span 8;
        }

        .img_container {
            grid-column: 13 / -1;
            align-self: flex-start;

            p {
                text-transform: uppercase;
                @include font-size(20, 44, 400, 5);
                text-align: center;
            }
            
            img {
                max-width: 452px;
                width: 100%;
                margin: auto;
                display: block; 
            }
        }

        @include rwd(885) {
            .selects_container {
                grid-column: 1 / -1;
                grid-row: 1;
            }
    
            .img_container {
                grid-column: 1 / -1;
                grid-row: 2;
                justify-self: flex-start;

                p {
                    text-align: left;
                }
            }
        }
    }

    .label {
        @include font-size(14, 18, 700, 5);
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .ego-form__field {
        max-width: 400px;
        border: 1px solid #E8E8E8;
        .value-placeholder {
            margin-top: 0 !important;
        }
    }

    .maintenance_page_bottom {
        padding-top: 120px;

        h4 {
            text-transform: uppercase;
            margin-bottom: 22px;
            @include font-size(26, 38, 400, 5);
        }
        
        &__kms {
            @include main-grid;
            background-color: #F7F7F7;
            padding: 32px;
    
            .maintenance-default-slide {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .maintenance_swiper {
                grid-column: 1 / span 8;
                width: 100%;
    
                .swiper-slide {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        @include font-size(26, 38, 400, 5);
                    }
                }

                .swiper-button-prev, .swiper-button-next {
                    color: black;

                    &::after {
                        font-size: 16px;
                    }
                }
            }
    
            .maintenance_page_bottom_text {
                grid-column: 13 / -1;
    
                p {
                    &:nth-child(1) {
                        @include font-size(20, 44, 700, 5);
                        text-transform: uppercase;
                        margin-bottom: 8px;
                    }
        
                    &:nth-child(2) {
                        @include font-size(14, 20, 400, 5);
                        margin-bottom: 8px;
                    }
        
                    &:nth-child(3) {
                        @include font-size(12, 18, 400, 0);
                    }
                }
            }

            @include rwd(885) {
                .maintenance_swiper {
                    grid-column: 1 / -1;
                    grid-row: 1;
                }
        
                .maintenance_page_bottom_text {
                    grid-column: 1 / -1;
                    grid-row: 2;
                    padding-top: 40px;
                }
            }
        }

        &__accordion {
            .accordion-container {
                margin-top: 60px;
                margin-bottom: 32px;
    
                .visible-part:first-child {
                    border-top: none;
                }
    
                .visible-part {
                    border-bottom: 1px solid $gray-600;
                    padding-top: 19px;
                    padding-bottom: 19px;
    
                    .active, .accordion:hover {
                        cursor: pointer;
                    }
    
                    .accordion {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
    
                        h5 {
                            text-transform: uppercase;
                        }
    
                        .toggle-minus {
                            display: none;
                            transform: rotate(180deg);
                        }
    
                        @include device-md {
                            h5 {
                                @include font-size(18, 28, 400);
                            }
                        }
                    }
            
                    .panel {
                        display: none;
                        overflow: hidden;
    
                        .fw-300 {
                            margin-top: 18px;
                            p {
                                margin-bottom: 0;
                                max-width: 966px;
                            }
                        }
    
                        .description {
                            p, li {
                                @include font-size(18, 28, 300, 0);
                            }

                            p {
                                margin-bottom: 0;
                        
                                b {
                                    font-weight: 600 !important;
                                }
                            }

                            p:last-child {
                                margin-bottom: 1.5rem;
                            }

                            li {
                                margin-bottom: 0;
                            }

                            li:last-child {
                                margin-bottom: 1.5rem;
                            }
                        }
                    }
                }
    
                @include device-md {
                    margin-top: 24px;
                }
            }

            .price-box {
                background-color: #F7F7F7;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 16px;
                margin-bottom: 113px;

                p {
                    margin-right: 16px;
                    @include font-size(18, 28, 400, 0);
                }

                @include rwd(885) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                }
            }
        }
    }
}
