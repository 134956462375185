.title-and-text {
    padding: 40px 0 0 0;
    margin-bottom: 40px;

    h3 {
        text-transform: uppercase;
        max-width: 957px;
        &.mb-24 {
            margin-bottom: 24px;
        }
    }

    p {
        max-width: 957px;
    }
}