.store-grid {
    background-color: $gray-200;

    &__container {
        @include main-grid();

        padding-top: 40px;
        padding-bottom: 57px;
        // background-color: rgba(red, 0.5);

        .text-link {
            display: flex;

            justify-self: end;

            grid-column: 5/8;

            // margin-top: 1.875rem;
            margin-bottom: 3.125rem;

            @include device-md("min") {
                display: none;
            }
        }

        &__filters {
            height: 100%;

            grid-column: 1/8;

            display: none;

            // background-color: rgba(blue, 0.5);

            @include device-md("min") {
                grid-column: 1/4;

                display: block;
            }

            @include device-lg("min") {
                grid-column: 1/7;
            }
        }

        h3 {
            @include font-size(16, 24, 700);

            text-transform: uppercase;

            // margin-bottom: 1.5rem;
        }
    }

    fieldset {
        padding: 1.5rem 0;
    }

    .fieldset-1 {
        display: grid;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;

        gap: 1.5rem;

        grid-auto-flow: row;
    }

    .fieldset-2,
    .fieldset-3 {
        border-top: 1px $gray-400 solid;

        .filter-container__wrapper {
            display: flex;

            flex-wrap: wrap;

            gap: 1.5rem;
        }
    }
}
