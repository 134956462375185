.background-black .recommendations {
    .item-container {
        .item {
            border-color: transparent;

            &-content {
                &_tag-and-date {
                    .--text-s {
                        color: $white;
                    }
                }

                &_title-and-subtitle {
                    color: $white;
                }
            }
        }
    }
}

.recommendations {
    // background-color: $gray-25;
    padding-top: 50px;
    padding-bottom: 50px;

    h3 {
        text-transform: uppercase;
        margin-bottom: 40px;

        @include rwd(800) {
            @include font-size(22, 30, 400, 5);
        }
    }

    .item-container {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @include rwd(800) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .item {
            width: 100%;
            border: 1px solid $gray-100;
            display: flex;
            flex-direction: column;
            max-width: 427px;

            img {
                height: 227px;
                width: 100%;
                object-fit: cover;
            }
            
            &-content{
                padding: 24px;
                display: flex;
                flex-direction: column;

                &_tag-and-date {
                    padding-bottom: 16px;
                    border-bottom: 1px solid $gray-100;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-bottom: 16px;

                    .tag {
                        background-color: $gray;
                        padding: 5px 8px;
                        display: block;
                        width: max-content;

                        p {
                            color: $white;
                            text-transform: uppercase;
                            @include font-size(12, 18, 400);
                        }
                    }

                    .--text-s {
                        color: $gray-600;
                    }
                }

                &_title-and-subtitle {
                    color: $gray-800;

                    .--text-m {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
}