.background-black .battery {
    background-color: $black;

    .battery_container_item {
        border-color: $white;
    }
}

.battery {
    background-color: $gray-25;
    padding-top: 80px;
    padding-bottom: 80px;

    h3 {
        text-transform: uppercase;
        margin-bottom: 64px;
    }

    &_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 40px;

        &_item {
            border-left: 1px solid $black;
            padding-left: 24px;

            h5 {
                margin-bottom: 8px;
                text-transform: uppercase;
                @include font-size(20, 44, 700, 5)
            }

            p {
                @include font-size(16, 24, 400);
                margin-bottom: 20px;
            }

            li {
                list-style: disc;
                margin-left: 24px;
                margin-bottom: 10px;
                padding-left: 5px;
            }
        }
    }

    @include device-lg {
        padding-top: 25px;
        padding-bottom: 40px;

        h3 {
            margin-bottom: 35px;
            border-bottom: 1px solid $gray-600;
            padding-bottom: 16px;
        }

        &_container {
            display: flex;
            flex-direction: column;

            &_item {
                border: none;
            }
        }
    }
}