.breadcrumbs {
    background-color: $white;

    margin-top: 67px;

    @include device-lg("min") {
        margin-top: 75px;
    }

    .content {
        padding-top: 14px;

        display: flex;
        flex-direction: column;

        gap: 1rem;

        width: 100%;

        @include device-md("min") {
            flex-direction: row;
            justify-content: space-between;

            padding-bottom: 14px;

            border-bottom: 2px solid $black;
        }

        ul {
            display: flex;

            width: 100%;

            @include device-md("min") {
                width: auto;

                border-bottom: none;

                padding-left: 0;
                padding-bottom: 0;
            }

            li {
                &:not(:last-child):after {
                    content: url("../images/icon-arrow-right.svg");

                    margin: 0 0.5rem;
                }
            }
        }
    }

    &__link {
        @include font-size(10, 20, 400);
        text-transform: uppercase;
    }

    .text-link {
        @include font-size(10, 20, 400);

        padding-right: 1rem;
    }
}
