.banner_title_button-desktop {
    position: relative;

    background-color: #000;

    min-height: 650px;
    height: max-content;

    padding: 0;

    margin-bottom: 40px;

    &.--store-bottom-black-fade {
        &::after {
            content: "";
            position: absolute;
            inset: 0 0 0 auto;
            z-index: 1;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(0, 0, 0, 1) 80%,
                rgba(0, 0, 0, 1) 100%
            );
            pointer-events: none;
        }

        .container {
            &::before,
            &::after {
                width: 0;
                background: none;
            }
        }
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 650px;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &::before {
            content: "";
            position: absolute;
            inset: 0 auto 0 0;
            z-index: 1;
            width: 43vw;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            pointer-events: none;
        }

        &::after {
            content: "";
            position: absolute;
            inset: 0 0 0 auto;
            z-index: 1;
            width: 10vw;
            background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(0, 0, 0, 1) 100%
            );
            pointer-events: none;
        }

        @include rwd(800) {
            &::before,
            &::after {
                display: none;
            }
        }

        .title-and-button {
            position: relative;
            z-index: 2;
        }
    }

    &.items {
        background-size: cover;
        background-position: top;
        min-height: 875px;
        width: 100%;

        .container {
            height: 100%;
            min-height: 875px;
        }

        .box-with-background {
            padding-bottom: 0;
        }
    }

    .banner_title_button_bottom {
        padding-bottom: 71px;

        z-index: 2;

        &_title {
            border-top: 2px solid $white;
            padding-top: 29px;
            margin-bottom: 26px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                @include font-size(24, 44, 400, -2);
                text-transform: uppercase;
                color: $white;
            }
        }

        &_extra_text {
            padding-bottom: 76px;
            height: max-content;
            position: relative;
            z-index: 2;

            .content {
                margin-top: 20px;
            }

            &_big {
                line-height: 40px;
                text-transform: uppercase;
            }

            &_small {
                @include font-size(14, 16, 400, 10);
                text-transform: uppercase;
            }
        }
    }
}

.banner_title_button-mobile {
    display: none;
    height: max-content;
    padding: 0;
    background-color: #f4f4f4;

    div {
        background-size: cover;
        background-repeat: no-repeat;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 101px);

        .title-and-button {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            height: 100%;

            .wrapper {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                height: 100%;
            }
        }
    }

    .banner_title_button_bottom {
        background-color: #f4f4f4;
        &_title {
            margin: 40px 0px;
            display: flex;
            flex-direction: column;
            p {
                text-transform: uppercase;
                @include font-size(20, 30, 400, 5);
                border-bottom: 2px solid $black;
                padding-bottom: 8px;
                margin-bottom: 8px;
            }
        }
    }

    .banner_title_button_bottom_extra_text {
        margin-top: 38px;

        .content {
            margin-bottom: 20px;
        }

        &_big {
            line-height: 40px;
            text-transform: uppercase;
        }

        &_small {
            @include font-size(14, 16, 400, 10);
            text-transform: uppercase;
        }
    }
}

@include rwd(800) {
    .banner_title_button-mobile {
        display: block;
    }

    .banner_title_button-desktop {
        display: none;
    }
}

@include rwd(1200) {
    .banner_title_button-mobile.items {
        display: block;
    }

    .banner_title_button-desktop.items {
        display: none;
    }
}
