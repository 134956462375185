.input {
    @mixin active-label {
        top: 7px;
        @include font-size(12, 12, 300, 0);
    }

    @mixin active-field {
        outline: none;
    }

    &__field {
        position: relative;

        width: 100%;

        // background-color: $white;
        background-color: transparent;

        border: 1px $gray-200 solid;

        input:not([type="radio"]) {
            width: 100%;
            height: 100%;

            @include font-size(16, 16, 400, 0);
            font-family: "Nobel", Arial, Helvetica, sans-serif;
            color: $gray-700;

            padding: 22px 16px 8px 16px;
        }

        label {
            width: 100%;
            height: 100%;

            padding-left: 1rem;

            @include font-size(16, 16, 400, 0);
            color: $gray;

            user-select: none;
            cursor: text;
            pointer-events: none;

            transition: all $timming ease-in-out;

            &.--floating-label {
                position: absolute;

                top: 15px;
                left: 0;
            }
        }

        // Focus
        // The focus state is controlled onFocus and onBlur events
        &.--focus {
            border-color: $gray-600;
        }

        input {
            border: none;

            &:focus,
            &:focus-visible {
                @include active-field;
            }
        }

        &:focus label {
            @include active-label;
        }

        // Filled
        &.--filled label {
            @include active-label;
        }

        // Invalid
        &.--invalid {
            border: 1px $danger solid;
        }

        &.--disabled {
            cursor: not-allowed;
            input {
                background-color: $gray-200;
                pointer-events: none;
            }

            label {
                color: $gray-600 !important;
                cursor: not-allowed !important;
            }
        }
    }

    &__text {
    }

    &__select {
        height: 50px;

        // padding: 0;

        cursor: pointer;

        &__wrapper {
            padding: 22px 16px 5px 16px;
        }

        &__controls {
            // background-color: rgba(red, 0.2);

            display: flex;

            justify-content: space-between;

            .caret {
                position: relative;
                top: -8px;

                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("../images/dropdown-arrow.svg") no-repeat center
                    center;
                background-size: contain;
            }

            &.--open {
                .caret {
                    transform: rotate(180deg);
                }
            }
        }

        &__placeholder {
            @include font-size(16, 16, 400, 0);
            color: $gray-700;
        }

        &__dropdown {
            position: relative;

            &__list {
                position: absolute;

                top: 0;
                left: 0;

                display: flex;
                flex-direction: column;

                width: 100%;
                max-height: 160px;

                opacity: 0;

                border: 1px solid $gray-25;
                background-color: $white;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                // border-radius: 8px;
                // padding: 8px 0;
                // width: 402px;
                // margin-left: -3px;
                // margin-top: 8px;

                pointer-events: none;

                overflow: auto;

                @include custom-scrollbars;

                z-index: 1;

                &.--open {
                    opacity: 1;

                    pointer-events: all;
                }
            }

            &__item {
                padding: 1rem;

                @include font-size(14, 20, 400);

                &:not(.--disabled):hover,
                &:not(.--selected):hover {
                    background-color: $gray-25;
                }

                &.--selected {
                    background-color: $gray-200 !important;
                    color: $black;
                }

                &.--disabled {
                    color: $gray-25;
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        }
    }

    // Custom styling for radio-buttons
    // https://moderncss.dev/pure-css-custom-styled-radio-buttons/
    .input__field.--radio,
    .input__field.--checkbox {
        display: grid;

        grid-template-columns: 1em auto;
        align-items: center;
        gap: 0.5em;

        border: none;

        input {
            width: 1.125rem;
            height: 1.125rem;

            margin: 0;

            appearance: none;

            border: 2px $gray-700 solid;
            border-radius: 50%;

            display: grid;
            place-content: center;

            // Override
            // think about ir later
            padding: 0;

            &::before {
                content: "";

                width: 0.6rem;
                height: 0.6rem;

                border-radius: 50%;

                box-shadow: inset 1em 1em $black;

                transform: scale(0);
                transition: $timming transform ease-in-out;
            }

            &:checked + label,
            &:hover + label {
                color: $gray-700;
            }

            &:checked::before,
            &:hover::before {
                transform: scale(1);
            }
        }

        label {
            // Overrides from .input__field label
            color: $black;
            padding: 0;
            pointer-events: all;
            cursor: pointer;
        }
    }

    &__checkbox {
        position: relative;

        &::before {
            content: "";

            width: 0.875rem;
            height: 0.875rem;

            // background-color: $black;
            box-shadow: inset 1rem 1rem $white !important;

            clip-path: polygon(
                28% 38%,
                41% 53%,
                75% 24%,
                86% 38%,
                40% 78%,
                15% 50%
            );
        }

        &:hover,
        &:checked {
            background-color: $black;
        }

        &:disabled {
            pointer-events: none;
            cursor: not-allowed;

            & ~ label {
                color: $gray-600 !important;

                pointer-events: none !important;
                cursor: not-allowed !important;
            }
        }
    }

    .input__field.--toggle {
        display: flex;

        &.--disabled {
            pointer-events: none;
            cursor: not-allowed;

            label {
                color: $gray-700 !important;

                pointer-events: none !important;
                cursor: not-allowed !important;
            }

            .toggle-switch {
                cursor: not-allowed !important;

                &::after {
                    background-color: $gray-700;
                }
            }
        }

        .toggle-switch {
            position: relative;

            width: 2.9rem;
            height: 1.25rem;

            background-color: $gray-400;

            border-radius: 0.75rem;

            cursor: pointer;

            pointer-events: none;

            &:after {
                content: "";

                position: absolute;

                top: 0;
                left: 0;

                width: 1.25rem;
                height: 1.25rem;

                background-color: $black;

                border-radius: 50%;

                transition: all $timming ease-in-out;
            }
        }

        input {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:checked ~ .toggle-switch {
                &:after {
                    background-color: $black;

                    transform: translateX(1rem);
                }
            }
        }

        label {
            color: $gray-700;

            transition: all $timming ease-in-out;

            cursor: pointer;
        }

        &.--box {
            background-color: #ececec;

            display: flex;
            justify-content: space-between;

            padding: 1rem;

            label {
                @include font-size(14, 20, 400);

                text-transform: uppercase;

                color: $black;
            }
        }
    }

    .input__field.--color {
        input {
            display: none;

            &:hover ~ label,
            &:checked ~ label {
                border: 2px black solid;
            }
        }

        label {
            display: block;

            width: 2.25rem;
            height: 2.25rem;

            border-radius: 50%;

            padding: 0.1rem;

            border: 2px transparent solid;
            border-radius: 5rem;

            cursor: pointer;
            pointer-events: all;

            &.--white {
                border: 1px #d9d9d9 solid;

                background: radial-gradient(
                    79.17% 79.17% at 50% 38.89%,
                    #ffffff 0%,
                    #d9d9d9 100%
                );
            }

            &.--black {
                background: radial-gradient(
                    66.67% 66.67% at 50% 27.78%,
                    #464646 0%,
                    #000000 100%
                );
            }

            &.--blue {
                background: radial-gradient(
                    61.11% 61.11% at 50% 27.78%,
                    #7eb1fe 0%,
                    #588ee0 100%
                );
            }

            &.--gray {
                background: radial-gradient(
                    61.11% 61.11% at 50% 27.78%,
                    #9c9c9c 0%,
                    #4e4e4e 100%
                );
            }

            &.--platinum {
                background: radial-gradient(
                    61.11% 61.11% at 50% 27.78%,
                    #d9d9d9 0%,
                    #9b9b9b 100%
                );
            }

            &.--red {
                background: radial-gradient(
                    61.11% 61.11% at 50% 27.78%,
                    #da0000 0%,
                    #750000 100%
                );
            }
        }
    }

    .message__optional {
        @include font-size(12, 16, 400);

        margin-top: 0.5rem;
    }

    &__message--error {
        margin-top: 0.5rem;

        p {
            color: $danger;
            @include font-size(12, 16, 400);
        }
    }

    &__range {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .box-minmax {
            margin-top: 5px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            color: #454545;
            font-family: Nobel;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;

            // span:first-child{
            //     margin-left: 10px;
            // }
        }

        .range-slider {
            width: 100%;
        }

        .rs-range {
            margin-top: 2px;
            width: 100%;
            -webkit-appearance: none;

            &:focus {
                outline: none;
            }

            &::-webkit-slider-runnable-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                box-shadow: none;
                // background: #B9C0C2;
                border-radius: 0px;
                border: 0px solid #010101;
            }

            // &::-moz-range-track {
            //     width: 100%;
            //     height: 1px;
            //     cursor: pointer;
            //     box-shadow: none;
            //     background: deeppink;
            //     border-radius: 0px;
            //     border: 0px solid #010101;
            // }

            &::-webkit-slider-thumb {
                box-shadow: none;
                border: 0px solid #ffffff;
                box-shadow: 0px 10px 10px rgba(0,0,0,0.25);

                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: #000;
                cursor: pointer;
                -webkit-appearance: none;
                margin-top: -5px;
            }

            // &::-moz-range-thumb{
            //     box-shadow: none;
            //     border: 0px solid #ffffff;
            //     box-shadow: 0px 10px 10px rgba(0,0,0,0.25);
            //     height: 42px;
            //     width: 22px;
            //     border-radius: 22px;
            //     background: deeppink;
            //     cursor: pointer;
            //     -webkit-appearance: none;
            //     margin-top: -20px;
            // }

            &::-moz-focus-outer {
                border: 0;
            }
        }

        .rs-label {
            position: relative;
            transform-origin: center center;
            background: #000;
            box-sizing: border-box;

            display: flex;
            padding: 8px 12px;
            align-items: center;
            align-self: stretch;

            color: #FFF;
            text-align: center;
            font-family: Nobel;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            width: max-content;
            margin-left: -16px;

            &::after {
                content: "%";
                display: block;
                font-size: 12px;
            }

            &::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-color: black;
                transform: rotate(45deg);
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(-5px) rotate(45deg);
            }
        }
    }
}

.--border-none {
    border: none !important;
}
