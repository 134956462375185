.legal-info {
    margin-bottom: 5rem;

    background-color: $gray-25;

    padding-top: 1.5rem;
    padding-bottom: 0.5rem;

    @include device-lg("min") {
        grid-column: 1/24;

        padding-top: 1.875rem;
        padding-bottom: 1.875rem;

        margin-bottom: 0;
    }

    p {
        @include font-size(14, 18, 300);

        text-transform: uppercase;

        @include device-lg("min") {
            text-transform: none;
        }

        &:nth-of-type(1) {
            margin-bottom: 1rem;
        }
    }

    &-doc {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 17px;
        border-bottom: 1px solid black;
        margin-bottom: 27px;

        @include device-lg("min") {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }


        .title {
            margin-bottom: 0;
            margin-top: 16px;
            text-transform: uppercase;
        }
    }
}
