// Overrides for react-toastify
// https://fkhadra.github.io/react-toastify/how-to-style/

.Toastify__toast {
    padding: 0;

    border-radius: 0;
}

.Toastify__toast-body {
    padding: 1.5rem 1rem;

    @include device-md("min") {
        padding: 2.5rem 3.625rem;
    }
}

.Toastify__toast-container {
    width: 100%;
    padding: 1.5rem;

    @include device-lg("min") {
        max-width: 67.75rem;
    }
}

.Toastify__progress-bar {
    background-color: $white;
}

.Toastify__close-button {
    position: absolute;

    top: 0.5rem;
    right: 0.5rem;
}

.toast {
    display: flex;

    flex-direction: column;

    @include device-md("min") {
        flex-direction: row;

        justify-content: space-between;
        align-items: center;
    }
    &__title {
        font-family: "Nobel", Arial, Helvetica, sans-serif;
        @include font-size(20, 30, 400, 5);

        margin-bottom: 1.125rem;

        text-transform: uppercase;

        @include device-md("min") {
            @include font-size(26, 38, 400, 5);
        }
    }

    &__product {
        display: flex;

        flex-direction: column;

        gap: 0.625rem;

        @include device-md("min") {
            flex-direction: row;

            gap: 5rem;
        }

        &__text {
            display: flex;

            gap: 0.875rem;

            @include device-md("min") {
                align-items: center;
            }

            p {
                @include font-size(14, 20, 400);

                @include device-md("min") {
                    @include font-size(18, 28, 400);
                }
            }
        }

        .type {
            width: 1.125rem;
            height: 1.125rem;
        }

        &__price {
            @include font-size(16, 24, 400);

            margin-bottom: 1rem;
            margin-left: 2rem;

            @include device-md("min") {
                @include font-size(20, 44, 400, 5);

                margin: 0;
            }
        }
    }
}
