.forms-corporativo {
    background-color: $black;
    position: relative;
    min-height: 573px;
    // min-height: calc(100vh - 122px);

    .wrapper {
        @include main-grid;
    }

    &_img {
        position: absolute;
        left: 0;
        top: 0;
        // width: 50%;
        // height: auto;
        width: 470px;
        height: 100%;
        object-fit: cover;
    }

    &_container {
        background-color: $white;
        grid-column: 13 / -1;
        padding: 32px 34px;
        margin-top: 56px;
        margin-bottom: 80px;
        position: relative;

        h3 {
            text-transform: uppercase;
            margin-bottom: 4px;
        }

        p {
            margin-bottom: 32px;
        }

        .ego-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            row-gap: 22px;

            &__field {
                margin-bottom: 0;

                &.--checkbox {
                    grid-column: 1 / -1;
                }

                &__checkbox {
                    width: 18px !important;
                    height: 18px !important;
                    position: relative;
                    border-radius: 50% !important;

                    &::before {
                        content: "";
            
                        width: 0.875rem;
                        height: 0.875rem;
                        // background-color: $black;
                        box-shadow: inset 1rem 1rem $white !important;
            
                        clip-path: polygon(
                            28% 38%,
                            41% 53%,
                            75% 24%,
                            86% 38%,
                            40% 78%,
                            15% 50%
                        );
                    }
                    
                    &:checked {
                        background-color: $black !important;
                        border-color: $black !important;
                    }

                    &:checked::before {
                        transform: scale(0.7) !important;
                    }
                }

                &.--select {
                    border: solid 1px $gray-200;
                }
            }

            .version_box {
                margin-top: 17px;
                grid-column: 1 / -1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &_version {
                    @include font-size(20, 30, 400, 5);
                    width: 100%;
                    margin-bottom: 24px;
                }

                &_img {
                    width: 371px;
                    height: 127.79px;
                    object-fit: cover;
                }
            }

            .btn-small {
                grid-column: 1 / -1;
                margin-top: 21px;
                width: 100%;
            }
        }
    }

    &_success {
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $white;
        position: relative;
        margin-top: 116px;
        text-align: center;
        min-height: 573px;

        p {
            margin-top: 32px;
            margin-bottom: 80px;
            max-width: 507px;
            text-align: center;
        }

        .btn-large {
            margin-bottom: 120px;
        }
    }

    @include rwd(800) {
        &_img {
            width: 100%;
        }

        .wrapper {
            display: flex;
            align-items: flex-start;
        }

        &_container {
            padding: 24px;
            margin-top: 40px;
            width: 100%;

            p {
                margin-bottom: 24px;
            }
        }
    }

    @include device-sm {
        padding-top: 0 !important;
        
        .ego-form {
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            .version_box {
                &_img {
                    width: 100%;
                }
            }

            .dropdown-mobile {
                display: flex;
            }

            .dropdown-mobile_text {
                display: flex;
                justify-content: space-between;
                padding-left: 24px;
                padding-right: 24px;
                margin-bottom: 20px;

                div {
                    font-size: 12px;
                    color: $black;
                }
            }

            .dropdown-mobile_icon {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 21px;
            }
        }
    }
}

.fcs {
    min-height: auto;
}

.--full-width {
    grid-column: 1 / -1 !important;
}

.dropdown-mobile {
    display: none;
}