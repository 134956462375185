.background-black .blog, .background-black .siblings {
    .tabs {
        &-list {
            &__item {
                &.active {
                    border-color: $white;
                }
            }
        }
    }
}

.background-black .blog-filters-mobile {
    &__button {
        color: $white !important;

        svg {
            path {
                stroke: $white;
            }
        }
    }
}

.blog, .siblings {
    overflow: hidden;
    // margin-bottom: 80px;
    margin-top: 16px;
    
    .tabs {
        &-list{
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 40px;
            align-items: center;
            
            text-align: center;
            @include font-size(16, 22, 700);
            width: 100%;
            // Oculta scrollbar
            overflow: scroll;
            scrollbar-width: none;

            @include device-sm{
                justify-content: space-between;
                gap: 8px;
            }
            &::-webkit-scrollbar {
                display: none;
                width: 0 !important;
            }
    
            &__item {
                display: inline-block;
                padding: 24px 0px;
                flex-shrink: 0;
                text-transform: uppercase;
                min-width: 162px;
                &.active {
                    border-bottom: 2px solid $black;
                    font-weight: 700;
                    z-index: 1;
                }
            }
        }

        &-buttons {
            position: relative;
            width: 100%;
            margin-bottom: 40px;
    
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom: 2px solid #B9C0C2;
                width: 100%;
            }
        }

        &-buttons-mobile {
            display: none;
        }

        &-panel{
            display: none;
            flex-direction: column;
            gap: 32px;
            animation: contentEffect $timming;
        }

        @include rwd(800) {
            &-buttons {
                display: none;
            }
            &-buttons-mobile {
                display: block;
                width: 100%;
                height: 100px;
                position: relative;
    
                .ego-form__field {
                    width: max-content;
                    position: absolute;
                    right: 0;
                }

                .tabs-list__item {
                    text-align: left;
                    padding: 20px 16px;
                }

            }
        }

        &-content {
            .blog-header {
                @include main-grid();
                margin-bottom: 80px;
                display: none;

                .text {
                    grid-column: 1 / span 7;

                    .tag {
                        background-color: $gray;
                        padding: 7px 16px;
                        display: block;
                        width: max-content;
                        margin-bottom: 16px;

                        p {
                            color: $white;
                            text-transform: uppercase;
                        }
                    }

                    h1 {
                        margin-bottom: 16px;
                    }
                }

                .image {
                    grid-column: 9 / -1;
                    height: 455px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                @include rwd(800) {
                    flex-direction: column-reverse;
                    margin-bottom: 58px;

                    .text {
                        display: flex;
                        flex-direction: column;

                        .tag {
                            margin-top: 25px;
                            margin-bottom: 24px;
                        }

                        h1 {
                            margin-bottom: 24px;
                        }
                    }
                }
            }

            .item-container {
                display: grid;
                gap: 16px;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: auto;
        
                @include rwd(800) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .item {
                    width: 100%;
                    border: 1px solid $gray-100;
                    display: flex;
                    flex-direction: column;
                    max-width: 427px;

                    img {
                        height: 227px;
                        width: 100%;
                        object-fit: cover;
                    }
                    
                    &-content{
                        padding: 24px;
                        display: flex;
                        flex-direction: column;

                        &_tag-and-date {
                            padding-bottom: 16px;
                            border-bottom: 1px solid $gray-100;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            margin-bottom: 16px;

                            .tag {
                                background-color: $gray;
                                padding: 5px 8px;
                                display: block;
                                width: max-content;
        
                                p {
                                    color: $white;
                                    text-transform: uppercase;
                                    @include font-size(12, 18, 400);
                                }
                            }
    
                            .--text-s {
                                color: $gray-600;
                            }
                        }
    
                        &_title-and-subtitle {
                            color: $gray-800;
    
                            .--text-m {
                                margin-top: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.blog {
    .tabs-panel {
        display: block;
    }
}

.blog-filters-mobile {
    display: none !important;
    width: 100%;

    @include rwd(800) {
        display: block !important;
    }

    &__button {
        @include font-size(12, 24, 400, 7.5);
        text-transform: uppercase;
        display: flex;
        padding: 0;
        margin-bottom: 53px;
        // margin-right: auto;
        margin-left: auto;

        svg {
            margin-left: 4px;
        }

        .blog-selected-option {
            margin-left: 5px;
        }
    }

    .blog-filters-mobile__modal {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.5);
        z-index: 10;
        display: none;

        &__content {
            background-color: $white !important;
            color: $black !important;
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 34px 24px 62px 24px;
            border-radius: 16px 16px 0px 0px;
            z-index: 11;
            width: 100vw;
            text-transform: uppercase;
            overflow-y: scroll;
            @include custom-scrollbars;

            .modal-close {
                display: flex;
                justify-content: space-between;
                margin-bottom: 18px;
            }

            .blog-tabs-list_mobile {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@keyframes contentEffect {
    from {
        opacity: 0;
        transform: translateY(70%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}