.input-container {
    margin-bottom: 2.5rem;

    &__title {
        font-size: 0.875rem;

        text-transform: uppercase;

        margin-bottom: 1rem;
    }

    .input {
        margin-bottom: 0.75rem;
    }

    &.--with-subtitle {
        .input-container__title {
            margin-bottom: 0.5rem;
        }

        .input-container__subtitle {
            @include font-size(14, 20, 400);
            margin-bottom: 1rem;
        }
    }

    &.--cart-total-cta {
        position: fixed;

        bottom: 0;
        left: 0;

        width: 100%;

        padding: 1rem;

        margin-bottom: 0;

        z-index: 10;

        @include device-lg("min") {
            position: relative;

            padding: 0;
            padding-top: 1.5rem;

            border-top: 2px solid #eeeeef;
        }

        .input-container__title,
        .input-container__subtitle {
            display: none;

            @include device-lg("min") {
                display: block;
            }
        }

        button {
            width: 100%;
        }
    }
}
