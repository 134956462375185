
.background-black .banner_text-desktop {
    .banner_text_element-text {
        .line {
            border-color: $white;
        }
    }
}
.background-black .banner_text-mobile {
    .banner_text_element-text {
        .line {
            border-color: $white;
        }
    }
}

.banner_text-desktop {
    padding: 40px 0;
    position: relative;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    
    &.large {
        min-height: 780px;
        .wrapper {
            min-height: 780px;
        }
    }
    
    &.short {
        min-height: 495px;
        .wrapper {
            min-height: 495px;
        }
    }

    .banner_text_element-text {
        display: flex;
        flex-direction: column;
        width: 360px;

        .title {
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        .line {
            border-left: 1px solid $black;
            height: 70px;
        }

        .description {
            margin-top: 16px;
            margin-bottom: 24px;
        }

        @include rwd(800) {
            width: 100%;
        }
    }

    .wrapper {
        // background-repeat: no-repeat;
        // background-size: cover;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // height: 100%;
        position: absolute;
        z-index: 2;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 1;
    }

    &.right {
        .wrapper {
            justify-content: flex-end;
        }
    }

    &.left {
        .wrapper {
            justify-content: flex-start;
        }
    }
}

.banner_text-mobile {
    display: none;
    padding-top: 20px;

    .banner_text_element-text {
        display: flex;
        flex-direction: column;
        // width: 360px;
        margin-left: auto;
        margin-right: auto;

        .title {
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        .line {
            border-left: 1px solid #000;
            height: 70px;
        }

        .description {
            margin-top: 16px;
            margin-bottom: 24px;
        }
    }

    img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        margin-top: 40px;
    }
}

@include rwd(800) {
    .banner_text-desktop {
        display: none;
    }

    .banner_text-mobile{
        display: flex;
    }
}
