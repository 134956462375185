.main_container_360 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;

    &_text {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;

        .title {
            text-transform: uppercase;
            margin-bottom: 8px;
            width: 298px;
        }

        .line {
            border-left: 1px solid #000;
            height: 70px;
        }

        .description_desktop {
            width: 418px;
        }
    }

    .description_mobile {
        display: none;
    }

    .selector_colors_container {
        width: 815px;

        img {
            width: 100%;
        }

        .rotation {
            margin-top: -83px;
            position: relative;
            z-index: 1;
        }

        .slider_360_container {
            height: 320px;

            img {
                height: 100%;
                object-fit: contain;
            }

            div {
                height: 100%;
            }
        }
    }

    .color_container {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        .colors {
            display: flex;
            justify-content: center;
            gap: 8px;
            flex-wrap: wrap;
        }

        .color_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: block;
                    margin: 0;
                }
            }

            .active {
                border: 1px solid $black;
            }
        }

        small {
            margin-top: 16px;
            text-align: center;
            min-height: 24px;
        }
    }

    @include rwd(768) {
        &_text {
            .title {
                width: 100%;
            }

            .description_desktop {
                display: none;
            }
        }

        .description_mobile {
            display: block;
            width: 100%;
            margin-top: 14px;
        }

        .selector_colors_container {
            width: 100%;
        }
    }
}
