.terms-conditions {
    padding-bottom: 100px;

    .title-and-button {
        .wrapper {
            padding: 0;
        }
    }

    &_container {
        padding-top: 80px;

        .title-and-button {
            padding-top: 0;
        }
        
        .terms-conditions_title {
            text-align: center;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .accordion-container {
            margin-top: 60px;
            max-width: 1024px;
            margin-left: auto;
            margin-right: auto;

            h1 {
                margin-bottom: 32px;
            }

            li:first-child {
                border-top: none;
            }

            li {
                border-bottom: 1px solid $gray-600;
                padding-top: 19px;
                padding-bottom: 19px;

                .active, .accordion:hover {
                    cursor: pointer;
                }

                .accordion {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;

                    h5 {
                        text-transform: uppercase;
                    }

                    .toggle-minus {
                        display: none;
                        transform: rotate(180deg);
                    }

                    @include device-md {
                        h5 {
                            @include font-size(18, 28, 400);
                        }
                    }
                }
        
                .panel {
                    display: none;
                    overflow: hidden;

                    .fw-300 {
                        margin-top: 18px;
                        p {
                            margin-bottom: 0;
                            max-width: 966px;
                        }
                    }

                    .description {
                        p {
                            margin-bottom: 1.5rem;
                    
                            b {
                                font-weight: 600 !important;
                            }
                        }
                    }
                }
            }

            @include device-md {
                margin-top: 24px;
            }
        }
    }
}