.banner-discount {
    background-color: $gray-200;
    padding-top: 24px;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        background-color: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);

        @include device-md("max") {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &__image {
        position: relative;
        height: 105px;
        background: #000000;
        background-image: url("/static/website/images/bg-banner-discounts.png");
        background-size: cover;
        height: 100%;
        &__gradient {
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%); 
        }
    }

    .discount-item {
        background-color: $black;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: "ToyotaType", sans-serif;
        padding: 32px 24px;

        @include device-lg("max") {
            flex-direction: column;
        }

        @include device-md("max") {
            flex-direction: row;
            justify-content: flex-start;
            padding: 24px 12px;
        }

        &__icons {
            display: flex;
            align-items: center;
        }

        &__amount {
            @include font-size(40, 40, 400);
            @include device-md("max") {
                @include font-size(32, 32, 400);
            }
        }

        &__text {
            @include font-size(14, 18, 400);

            @include device-lg("max") {
                text-align: center;
            }

            @include device-md("max") {
                text-align: start;
            }
        }
    }
}