.comparator {
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    background-size: contain;

    .title-and-button {
        padding-top: 40px;

        .wrapper {
            align-items: flex-end;
        }
    }

    .comparator-container {
        @include main-grid;
        margin-top: 20px;
        margin-bottom: 80px;

        .comparator-filters {
            grid-column: 1 / span 5;
            align-self: flex-start;
            display: flex;
            flex-direction: column;

            .--text-m {
                text-transform: uppercase;
                margin-bottom: 27px;
            }
    
            .input {
                margin-bottom: 16px;
            }

            .--text-s {
                color: $gray-700;
                margin-top: 40px;
                margin-bottom: 19px;
            }
        }

        .comparator-filters-mobile {
            display: none;
            width: 100%;
            grid-column: 1/-1;
            &__button {
                @include font-size(12, 24, 400, 7.5);
                text-transform: uppercase;
                display: flex;
                padding: 0;
                margin-bottom: 53px;
                // margin-right: auto;
                margin-left: auto;

                svg {
                    margin-left: 4px;
                }
            }

            &__modal {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0,0,0,.5);
                z-index: 10;

                &__content {
                    background-color: $white;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    padding: 34px 24px 62px 24px;
                    border-radius: 16px 16px 0px 0px;
                    z-index: 11;
                    width: 100vw;
                    text-transform: uppercase;
                    overflow-y: scroll;
                    @include custom-scrollbars;

                    .modal-close {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 18px;
                    }

                    .input, .car {
                        margin-bottom: 32px;
                        margin-top: 32px;
                    }

                    .input:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    
        .comparator-content {
            grid-column: 7 / -1;
            display: flex;
            justify-content: space-between;
            align-self: flex-start;
            width: 100%;
            gap: 16px;
            overflow-x: scroll;
            @include custom-scrollbars;

            .comparator-items {
                width: 100%;
                min-width: 295px;

                .ego-form__field {
                    margin-bottom: 16px;
                    background-color: $white;
                    border: 1px solid $gray-200;
                    // width: 294px;
                    width: 100%;

                    &.first {
                        z-index: 6;
                    }
                    
                    .value-placeholder {
                        text-transform: none;
                    }
                    
                    .options-container {
                        background: $white;
                        border: 1px solid $gray-600;
                        border-top: none;
                        width: calc(100% + 2px);
                        max-height: 300px;
                        overflow-y: scroll;
                        margin-left: -1px;
                        padding: 24px 0px 8px 0px;

                        li {
                            padding: 16px;
                            @include font-size(14, 20, 400);
                        
                            &:hover {
                                background-color: $gray-200;
                            }
                        }
                    }
                }

                .comparator-card-empty {
                    background-color: $gray-25;
                    border: 1px solid $gray-100;
                    width: 100%;
                    height: 422px;
                    padding: 24px;
                    position: relative;
                    
                    img {
                        width: 100%;
                        object-fit: contain;
                        margin-top: 70px;
                        margin-bottom: 24px;
                    }

                    svg {
                        position: absolute;
                        right: 50%;
                        top: 154px;
                        transform: translateX(50%);
                    }

                    .--text-s {
                        width: 210px;
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .comparator-card-detail {
                    background-color: $white;
                    border: 1px solid $gray-100;
                    padding: 24px;

                    .title {
                        @include font-size(24, 24, 400);
                        width: 210px;
                    }

                    .green-tax {
                        @include font-size(14, 16, 400);
                        color: $gray;
                    }

                    .--text-s {
                        color: #484848;
                    }

                    .--link-s {
                        @include font-size(10, 36, 400, 5);
                    }

                    .--link-m {
                        @include font-size(14, 16.8, 400);
                        text-align: center;
                    }

                    img {
                        width: 100%;
                        object-fit: contain;
                        margin-top: 24px;
                    }

                    .text-link {
                        height: 36px;
                        display: block;
                        margin-bottom: 9px;
                    }

                    &_detail {
                        .detail-title {
                            border-bottom: 1px solid $black;
                            margin-top: 32px;
                            padding-bottom: 8px;
                            margin-bottom: 8px;
                            text-transform: uppercase;
                        }
                    }

                    .btn-outline-black {
                        margin-top: 32px;
                        margin-bottom: 16px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        @include device-lg {
            .comparator-filters {
                display: none;
            }

            .comparator-filters-mobile {
                display: block;
            }

            .comparator-content {
                grid-column: 1 / -1;
            }
        }
    }

    .input__field__checkbox {
        position: relative;

        &::before {
            content: "";

            width: 0.875rem;
            height: 0.875rem;

            // background-color: $black;
            box-shadow: inset 1rem 1rem $white !important;

            clip-path: polygon(
                28% 38%,
                41% 53%,
                75% 24%,
                86% 38%,
                40% 78%,
                15% 50%
            );
        }

        &:hover,
        &:checked {
            background-color: $black;
        }
    }
}

#models-comparator-root {
    padding-top: 0 !important;
}

.--error {
    color: $danger;
}