.item-detail {
    background-color: $white;

    @include device-lg("min") {
        padding-top: 2rem !important;
    }

    &__grid {
        @include device-lg("min") {
            @include main-grid;

            align-items: start;
        }
    }

    .box-left {
        @include device-lg("min") {
            grid-column: 1/14;

            display: grid;
            grid-template-columns: 1fr;

            align-items: center;
        }
    }

    .box-right {
        @include device-lg("min") {
            grid-column: 17/24;
        }

        &.--mantencion {
            display: grid;

            @include device-lg("min") {
                display: block;
            }

            .input {
                grid-row: 2/3;
            }

            .price-and-discount {
                grid-row: 1/2;
            }

            .quiero-saber-que-incluye {
                grid-row: 3/4;
            }

            .btn-add-to-cart {
                grid-row: 5/6;
            }
        }

        .green-tax {
            text-align: right;
            padding: 0 24px;
            @include font-size(14, 16, 400);
            color: $gray;
        }
    }

    .title-container {
        margin-top: 2.5rem;
        margin-bottom: 0.5rem;

        @include device-lg("min") {
            margin-top: 0;
            margin-bottom: 2.5rem;
        }

        .title {
            // margin-top: 2.5rem;
            margin-bottom: 0.75rem;

            // @include device-lg("min") {
            //     margin-top: 0;
            //     margin-bottom: 2.5rem;
            // }
        }

        .subtitle {
            @include font-size(14, 20, 400);
        }

        .badge-card {
            position: initial;
            width: max-content;
            margin-bottom: 24px;
        }
    }

    &__image {
        width: 100%;

        @include device-lg("min") {
            grid-column: 1/2;
        }
    }

    .badge {
        margin-bottom: 2.5rem;

        @include device-lg("min") {
            grid-column: 1/2;
            grid-row: 1/2;

            justify-self: start;
        }
    }

    .input {
        margin-bottom: 2.5rem;
    }

    // .text-link {
    //     margin-bottom: 2.5rem;
    // }

    .box-with-border {
        margin-bottom: 1rem;
    }

    .discount {
        margin-bottom: 2.5rem;
    }

    // .btn-large {
    //     margin-bottom: 2.5rem;

    //     // width: auto;

    //     @include device-lg("min") {
    //         margin-bottom: 5rem;
    //     }
    // }

    .mobile-only {
        display: block;

        @include device-lg("min") {
            display: none;
        }
    }

    .desktop-only {
        display: none;

        @include device-lg("min") {
            display: block;
        }
    }

    // This removes the margin bottom when a inputs
    // its in the ItemDetail
    .input {
        margin-bottom: 0;
    }

    .book-form {
        margin-bottom: 2.5rem;
    }
}
