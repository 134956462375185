.error-page {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    height: 730px;
    
    &_text {
        @include main-grid;
        width: 100%;
        height: 100%;
        
        &_content {
            grid-column: 3 / -1;
            max-width: 550px;
            display: flex;
            flex-direction: column;
            
            h1 {
                margin-bottom: 16px;
            }
    
            p {
                margin-bottom: 64px;
            }
        }

    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 530px;
        max-height: 730px;
        object-fit: cover;
    }

    @include rwd(1260) {
        display: flex;

        &_text {
            display: flex;
        }
        
        img {
            position: relative;
        }
    }
    
    @include rwd(1024) {
        margin-top: 123px;
        height: auto;
        
        &_text {
            padding-top: 80px;
            padding-bottom: 80px;
            justify-content: center;
            text-align: center;
        
            a {
                margin-left: auto;
                margin-right: auto;
            }
        }

        img {
            display: none;
        }
    }

    @include rwd(719) {
        &_text {
            padding-top: 0;
        }
    }
}