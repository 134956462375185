.badge {
    display: flex;

    align-items: center;
    justify-content: center;

    gap: 1rem;

    padding: 0.875rem;

    img {
        display: block;

        width: 1.5rem;
        height: 1.5rem;
    }
}

.badge-card {
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 8% 50%, 0% 0%);

    padding: 0.5rem 1.5rem 0.5rem 1rem;

    position: absolute;

    right: 0;
    bottom: 1.5rem;

    z-index: 10;

    p {
        color: $white;

        @include font-size(12, 14, 400);
    }
}

.badge-detail {
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 8% 50%, 0% 0%);

    width: max-content;

    padding: 0.5rem 1.5rem 0.5rem 2rem;

    position: relative;

    margin-bottom: 1.5rem;

    @include device-lg("min") {
        margin-bottom: 2rem;
    }

    p {
        color: $white;

        @include font-size(16, 20, 400);

        width: 100%;
    }
}
