.storybook {
    overflow: hidden !important;
    
    section {
        margin-top: 0;
    }

    .--wrapper {
        padding-top: 100px;
        h2 {
            border-top: 1px solid $gray-600;
            margin-top: 150px;
            margin-bottom: 15px;
            padding-top: 15px;
            text-align: center;
        }
    }

    .title-h1 {
        margin-top: 50px;
    }

    h2 {
        margin-top: 150px;
        margin-bottom: 15px;
        padding-top: 15px;
        text-align: center;
    }

    &__main-grid {
        @include main-grid(600px);
        
        span {
            height: 100%;
            border: 1px dashed red;

            &:nth-child(1) {
                grid-column: 1/span 1;
            }
            &:nth-child(2) {
                grid-column: 3/span 1;
            }
            &:nth-child(3) {
                grid-column: 5/span 1;
            }
            &:nth-child(4) {
                grid-column: 7/span 1;
            }
            &:nth-child(5) {
                grid-column: 9/span 1;
            }
            &:nth-child(6) {
                grid-column: 11/span 1;
            }
            &:nth-child(7) {
                grid-column: 13/span 1;
            }
            &:nth-child(8) {
                grid-column: 15/span 1;
            }
            &:nth-child(9) {
                grid-column: 17/span 1;
            }
            &:nth-child(10) {
                grid-column: 19/span 1;
            }
            &:nth-child(11) {
                grid-column: 21/span 1;
            }
            &:nth-child(12) {
                grid-column: 23/span 1;
            }
        }

        @include device-lg {
            @include main-grid(600px);

            span:nth-child(n+7) {
                display: none;
            }
        }

        @include device-md {
            @include main-grid(300px);

            span:nth-child(n+5) {
                display: none;
            }
        }
    }

    &__buttons, &__text-links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 15px;
    }

    &__select, &__input {
        max-width: 500px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}