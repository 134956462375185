.corporate_home_middle_bottom {
    &-desktop, &-mobile {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
        min-height: calc(100vh - 95px);
        height: 810px;
        padding: 0;
        margin: 11px auto 23px auto;
        color: $white;
        max-width: 1440px;
        display: block;

        .wrapper {
            height: 100%;
            padding-bottom: 40px;
        }

        .title-and-button {
            height: 100%;
            padding-top: 85px;

            .btn-large {
                width: max-content;
            }
        }

        @include device-xl(min) {
            min-height: 100%;
        }
    }

    &-mobile {
        display: none;
    }

    @include device-lg() {
        &-desktop {
            display: none;
        }

        &-mobile {
            display: block;
            background-position: left bottom;
            margin-top: 0;
        }
    }
}