.modal {
    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 999;

    background-color: $black;

    display: none;

    &.--open {
        display: block;
    }

    // TODO: donde deberia ir esto?
    // pointer-events: none;

    &__overlay {
        @include main-grid();

        overflow-y: scroll;

        //Overrides
        align-items: normal;

        width: 100%;
        height: 100%;

        padding: 4rem 0;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    &__box {
        grid-column: 3/10;

        @include device-lg("min") {
            grid-column: 7/18;
        }

        background-color: $white;

        padding: 2rem;

        &__header {
            display: flex;

            justify-content: space-between;
            align-items: center;

            margin-bottom: 2.5rem;

            text-transform: uppercase;
        }
    }

    &__content {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;

        border-bottom: 1px #b9c0c2 solid;

        h3 {
            @include font-size(14, 18, 700);

            text-transform: uppercase;

            margin-bottom: 1rem;
        }

        p {
            @include font-size(14, 24, 400);
        }

        ul {
            list-style-type: disc;
            margin-left: 2rem;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        li {
            @include font-size(14, 24, 300);
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    img {
        width: 100%;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
