.tabs {
    &__controls {
        &.--box {
            background-color: #ececec;

            padding: 0.3125rem;

            // SACAR ESTO UNA VEZ SE HAYA CREADO LA LOGICA PARA LOS DESCUENTOS
            // display: none;

            // VOLVER A DESCOMENTAR ESTO PARA MOSTRARLO
            display: flex;
        }
    }

    &__control {
        background-color: transparent;

        flex: 1 1 50%;

        text-transform: uppercase;

        @include font-size(12, 18, 400);

        &.--active {
            background-color: white;

            padding: 0.625rem 0;

            font-weight: 700;

            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        }
    }

    &__content {
        display: none;
        margin-top: 0.875rem;
        
        &.finance {
            background-color: #F7F7F7;
            display: none;
            
            &.step-1 {
                padding: 28px 25px 41px 25px;

                p {
                    &:last-of-type {
                        margin-top: 24px;
                    }
                }
            }

            &.step-2 {
                padding: 28px 35px 55px 35px;
            }
            
            &.--active {
                &.--step-active {
                    display: block;
                }
            }

            &.--hidden {
                display: none;
            }

            p {
                margin-bottom: 1rem;
            }

            .input-container {
                margin-bottom: 1.5rem;
            }

            .input__select  {
                background-color: white;
                margin-top: 1rem;
            }

            .btn-outline-black {
                width: 100%;
            }

            .extra-info {
                padding-top: 0;

                &__container {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                &__label {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #ECECEC;
                    padding-bottom: 8px;
                    margin-top: 0;
                    @include font-size(14, 20, 400);

                    &:last-child {
                        border-bottom: none;
                    }

                    .extra-info__price {
                        text-align: right;
                        font-weight: 700;

                        &.red {
                            color: #EA0F00;
                        }
                    }
                }

                &__text-container {
                    border-top: none;
                    margin-top: 0;
                    padding-top: 16px;
                }

                &__text {
                    color: #191919;
                    margin-bottom: 0;
                    @include font-size(12, 18, 400);
                }
            }

            // @include device-sm {
            //     width: 100vw;
            //     margin-left: -24px;
            // }
        }

        &.--active {
            display: block;
        }
    }

    .extra-info {
        padding: 1.625rem 0 2.5rem 0;

        &__container {
            margin-bottom: 1.5rem;
        }

        &__label,
        &__price {
            @include font-size(14, 26, 400);
        }

        &__price {
            font-weight: 700;
        }

        &__text-container {
            border-top: 1px #ececec solid;

            margin-top: 1.5rem;
            padding-top: 1.5rem;

            p {
                @include font-size(14, 20, 400);
            }
        }
    }

    a {
        margin-bottom: 0;
    }
}

.tabs-360 {
    &.tabs-buttons {
        position: relative;
        // width: 100%;
        margin-bottom: 24px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 2px solid #b9c0c2;
            width: 100%;
        }
    }

    .tabs-list {
        display: none;
        @include device-md("min") {
            justify-content: space-between;
            gap: 8px;

            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 40px;
            align-items: center;
        }

        width: 100%;
        // Oculta scrollbar
        overflow: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
            width: 0 !important;
        }

        &__item {
            display: inline-block;
            padding: 24px 0px;
            flex-shrink: 0;
            text-transform: uppercase;
            min-width: 162px;
            &.active {
                border-bottom: 2px solid $black;
                font-weight: 700;
                z-index: 1;
            }
        }

        &__anchor {
            pointer-events: none;
        }

        text-align: center;
        @include font-size(16, 22, 700);
    }

    .gallery-filters-mobile {
        display: none;
        width: 100%;

        @include rwd(800) {
            display: block;
        }

        &__button {
            @include font-size(12, 24, 400, 7.5);
            text-transform: uppercase;
            display: flex;
            padding: 0;
            margin-bottom: 53px;
            // margin-right: auto;
            margin-left: auto;

            svg {
                margin-left: 4px;
            }
        }

        .gallery-filters-mobile__modal {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            z-index: 10;
            display: none;

            &__content {
                background-color: $white;
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 34px 24px 62px 24px;
                border-radius: 16px 16px 0px 0px;
                z-index: 11;
                width: 100vw;
                text-transform: uppercase;
                overflow-y: scroll;
                @include custom-scrollbars;

                .modal-close {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 18px;
                }

                .tabs-list_mobile {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
