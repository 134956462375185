.checkout {
    &__item {
        width: 100%;

        // padding-bottom: 1.25rem;

        // margin-bottom: 1.5rem;

        display: flex;

        flex-direction: column;

        margin-bottom: 1.5rem;

        transition: all $timming ease-in-out;

        &__list {
        }

        &__container {
            display: flex;

            justify-content: space-between;
            align-items: center;

            // margin: 0 auto;

            border-bottom: 1px $black solid;
        }

        &__label {
            // @include font-size(20, 30, 400);
            @include font-size(20, 44, 400);

            text-transform: uppercase;
            // text-transform: none;

            cursor: pointer;

            // @include device-lg("min") {
            //     @include font-size(20, 44, 400);
            // }
        }

        &__content {
            // margin-top: 1.25rem;
            // padding-top: 0;

            display: flex;

            flex-direction: column;

            transition: all $timming ease-in-out;

            overflow: hidden;

            // This style applies only when the content is a p
            // If the content its a component it will not going to the a p
            // & > p {
            //     @include device-lg("min") {
            //         padding-right: 2rem;
            //     }
            // }

            .dealership-title {
                margin: 1.5rem 0;
            }

            .dealership-text {
                @include font-size(18, 28, 300);

                strong {
                    font-weight: 400;
                }

                &.date {
                    margin-bottom: 1rem;
                }
            }

            .button-continue {
                align-self: flex-end;

                margin-top: 1.5rem;
            }
        }

        // Active state
        &.--active {
            // background-color: red;
            margin-bottom: 5rem;
        }
    }

    &__discount {
        background-color: $gray-25;
        margin-bottom: 16px;
        padding: 0.75rem 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        &__detail {
            @include font-size(14, 20, 400);
            display: flex;
            align-items: center;
            
            img {
                margin-right: 12px;
            }

            @include device-md("min") {
                flex-direction: row;

                justify-content: space-between;
            }
        }
        
        &__price {
            @include font-size(18, 28, 700);
            white-space: nowrap;
        }

        .mobile-img {
            display: block;
    
            @include device-md("min") {
                display: none;
            }
        }
    
        .desktop-img {
            display: none;
    
            @include device-md("min") {
                display: block;
            }
        }
    }

    &__total {
        background-color: $gray-25;

        padding: 0.75rem 1.5rem;

        display: flex;

        flex-direction: column;
        align-items: center;

        gap: 0.5rem;

        @include font-size(16, 24, 400);

        @include device-md("min") {
            flex-direction: row;

            justify-content: space-between;
        }

        &__quantity {
            margin-left: 0.5rem;
            margin-right: 1rem;

            @include font-size(14, 20, 400);
        }

        &__price {
            @include font-size(18, 28, 700);
        }
    }

    &__input-list {
        display: flex;

        flex-direction: column;

        gap: 1rem;
    }

    &__label-container {
        display: flex;

        gap: 0.5rem;
    }

    &__inputs-group {
        display: flex;

        flex-direction: column;

        gap: 1rem;

        @include device-lg("min") {
            flex-direction: row;

            gap: 0.875rem;
        }

        .input {
            flex: 1;
        }
    }

    &__radio {
        margin-bottom: 0.5rem;
    }

    &__webpay {
        padding: 1.5rem;
        margin-top: 2.5rem;

        border: 2px $gray-600 solid;

        @include device-lg("min") {
            padding: 2.625rem 3.25rem;
        }

        .purchase-total {
            @include font-size(18, 28, 700);
            color: $gray-600;

            text-align: center;

            margin-bottom: 0.5rem;
        }

        .webpay {
            color: $gray-600;

            display: flex;

            justify-content: center;

            flex-wrap: wrap;

            gap: 0.5rem;

            margin-bottom: 1.625rem;
        }

        button {
            width: 100%;
        }

        &.--active {
            border-color: $black;

            transition: border-color $timming ease-in-out;

            .purchase-total,
            .webpay {
                color: $black;

                transition: color $timming ease-in-out;
            }
        }
    }
}
