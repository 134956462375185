.accordion {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.filters {
        .wrapper {
            padding: 0;
        }
    }

    &__item {
        width: 100%;

        padding-bottom: 1.25rem;

        margin-bottom: 2.5rem;

        border-bottom: 1px $gray-600 solid;

        // @include device-lg("min") {
        //     width: 80%;
        // }

        &__container {
            display: flex;

            margin: 0 auto;

            justify-content: space-between;
            align-items: center;

            .arrow {
                content: "";
                display: block;
                width: 18px;
                height: 12px;
                margin-right: 2rem;
                background: url("../images/dropdown-arrow.svg") no-repeat center
                    center;
            }
        }

        &__label {
            @include font-size(18, 28, 400);

            text-transform: none;

            cursor: pointer;

            @include device-lg("min") {
                @include font-size(22, 30, 400);

                text-transform: uppercase;
            }
        }

        &__content {
            // margin-top: 1.25rem;
            // padding-top: 0;

            transition: all $timming ease-in-out;

            overflow: hidden;

            // This style applies only when the content is a p
            // If the content its a component it will not going to the a p
            & > p {
                @include device-lg("min") {
                    padding-right: 2rem;
                }
            }

            ul {
                padding-left: 2rem;

                li {
                    list-style: disc;
                }
            }

            .color-container {
                display: flex;

                justify-content: space-between;
                flex-wrap: wrap;

                gap: 1rem;

                .input__field {
                    border: none;
                }
            }
        }

        // Filters
        &.--filter {
            border: none;

            margin-bottom: 0;

            .arrow {
                margin: 0;
            }

            .accordion__item__label,
            label {
                text-transform: uppercase;
            }

            .accordion__item__container,
            .accordion__item__content {
                padding: 0 1.5rem;
            }

            .accordion__item__content {
                background-color: $gray-25;

                .input {
                    padding: 1rem 0;
                }
            }
        }

        // Active state
        &.--active {
            .arrow {
                transform: rotate(180deg);
            }

            // .accordion__item__content {
            //     transition: height 2s ease-in-out;
            // }
        }
    }
}
