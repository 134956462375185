.benefits {
    padding-top: 80px;
    padding-bottom: 40px;

    &_container {

        h3 {
            text-transform: uppercase;
            margin-bottom: 64px;
        }

        &_grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            justify-content: space-between;
            column-gap: 40px;

            &_element {
                display: flex;
                align-items: flex-start;
                margin-bottom: 80px;

                &_text {
                    display: flex;
                    flex-direction: column;
                    
                    .title {
                        @include font-size(20, 30, 700, 5);
                        text-transform: uppercase;
                        margin-bottom: 8px;
                    }
                    .text {
                        max-width: 352px;
                    }
                }

                img {
                    width: 60px;
                    object-fit: contain;
                    margin-right: 40px;
                }
            }

            @include rwd(600) {
                display: flex;
                flex-direction: column;
                // width: max-content;
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &_element {
                    flex-direction: column;
                    margin-bottom: 32px;

                    
                    &_text {
                        .text {
                            max-width: none;
                        }
                    }
                }

                img {
                    margin-bottom: 24px;
                }
            }
        }
    }
}