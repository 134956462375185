.background-black .two-columns {
    &_title {
        border-color: $white;
    }
}

.two-columns {
    padding-top: 40px;
    padding-bottom: 40px;

    &_title {
        @include font-size(20, 44, 400, 5);
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid $black;
        margin-bottom: 40px;
        text-transform: uppercase;
        margin-top: 38px;
    }

    &_block {
        @include main-grid;
        align-items: start;

        &_item:nth-child(odd) {
            grid-column: 1 / span 11;
        }

        &_item:nth-child(even) {
            grid-column: 13 / -1;
        }

        &_item {
            margin-bottom: 80px;
            width: 100%;

            img {
                width: 100%;
            }

            &_title {
                margin-top: 38px;
                margin-bottom: 16px;
            }

            &_title, &_text {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }

    @include device-lg {
        &_title {
            margin-bottom: 24px;
            margin-top: 16px;
        }

        &_block {
            display: flex;
            flex-direction: column;

            &_item {
                margin-bottom: 48px;

                &_title {
                    margin-top: 24px;
                    margin-bottom: 16px;
                }
            }
        }
    }
}