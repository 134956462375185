.series-grid {
    padding-top: 25px;
    padding-bottom: 65px;

    &_title {
        padding-bottom: 6px;
        border-bottom: 2px solid $black;
        margin-bottom: 23px;
        text-transform: uppercase;
    }

    &_container {
        display: grid;
        gap: 22px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;

        @include rwd(800) {
            display: flex;
            flex-direction: column;
        }

        &_card {
            height: max-content;

            &-title {
                @include font-size(20, 44, 400, 5);
                color: $black;
                margin-bottom: 7px;
                text-transform: uppercase;
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}