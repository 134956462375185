.cart {
    // padding override
    .title-and-button {
        padding-top: 2rem;
        padding-bottom: 2.5rem;

        @include device-lg("min") {
            padding-top: 3.375rem;
            padding-bottom: 1.5rem;
        }

        .wrapper {
            padding: 0 !important;

            @include device-lg("min") {
                align-items: center !important;
            }

            .text-link {
                flex-shrink: 0;
            }
        }
    }

    &__empty {
        margin-bottom: 5rem;

        &__content {
            width: 100%;

            margin: 0 auto;
            padding: 0 1rem;

            display: flex;

            flex-direction: column;

            align-items: center;

            @include device-lg("min") {
                // This is in Figma
                // width: 18.125rem;

                width: 13rem;
            }

            img {
                width: 3.75rem;
                height: 3.75rem;

                margin-bottom: 1.5rem;

                @include device-lg("min") {
                    width: 7.5rem;
                    height: 7.5rem;
                }
            }

            p {
                margin-bottom: 2.5rem;

                text-transform: uppercase;
                text-align: center;
            }
        }
    }

    &__grid {
        @include main-grid();

        align-items: start;

        margin-bottom: 2.5rem;

        @include device-lg("min") {
            margin-bottom: 6.25rem;
        }
    }

    .box-1 {
        grid-column: 1/8;

        @include device-md("min") {
            grid-column: 1/12;
        }

        @include device-lg("min") {
            grid-column: 1/14;
        }
    }

    .box-2 {
        grid-column: 1/8;
        grid-row: 2/3;

        @include device-md("min") {
            grid-column: 1/12;
        }

        @include device-lg("min") {
            grid-column: 17/24;
            grid-row: 1/2;

            margin-top: 3.375rem;
        }

        .input-container {
            display: flex;

            flex-direction: column;

            .text-link {
                @include font-size(10, 36, 400);

                @include device-lg("min") {
                    align-self: flex-end;
                }
            }
        }
    }

    &__added-item {
        border: 2px #eeeeef solid;

        // padding: 0.375rem;
        padding: 1.125rem;

        display: flex;

        flex-direction: row;
        justify-content: space-between;

        gap: 1rem;

        @include device-lg("min") {
            padding: 1.5rem;
        }

        &.--mantencion {
            background-color: #b9c0c2;
        }

        &__container {
            display: flex;

            flex-direction: column;

            gap: 1rem;

            margin-bottom: 2.5rem;

            // @include device-lg("min") {
            //     margin-bottom: 0;
            // }
        }

        &__content {
            display: flex;

            align-items: center;
            justify-content: center;

            gap: 1rem;

            // https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
            min-width: 0;

            @include device-lg("min") {
                gap: 1.25rem;
            }
        }

        &__image {
            width: 3.75rem;
            height: 1.375rem;

            flex-grow: 0;
            flex-shrink: 0;

            overflow: hidden;

            @include device-lg("min") {
                width: 6.125rem;
                height: auto;
            }
        }

        &__title-and-description {
            display: flex;

            overflow: hidden;

            @include device-lg("min") {
                align-items: flex-start;

                gap: 0.875rem;
            }

            .type {
                display: none;

                @include device-lg("min") {
                    display: block;

                    margin-top: 0.25rem;
                }
            }

            .container {
                flex: 1;
                min-width: 0;
            }
        }

        &__title {
            @include font-size(18, 28, 400);

            // width: 250px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__description {
            @include font-size(14, 18, 300);

            // width: 250px;
            margin-bottom: 0.5rem;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__counter-and-price {
            flex-shrink: 0;

            display: flex;

            flex-direction: column-reverse;

            justify-content: flex-end;
            align-items: flex-end;

            gap: 1.25rem;

            @include device-lg("min") {
                flex-direction: row;

                justify-content: center;
                align-items: center;

                gap: 1.5rem;
            }
        }

        &__price {
        }
    }
}
