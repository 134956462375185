.article {
    display: flex;
    flex-direction: column;
    padding-top: 72px;
    padding-bottom: 80px;

    &_top {
        width: 100%;
        border-bottom: 1px solid $gray-600;
        padding-bottom: 24px;

        &_tag {
            background-color: $gray;
            padding: 5px 8px;
            display: block;
            width: max-content;
            margin-bottom: 24px;
    
            p {
                color: $white;
                text-transform: uppercase;
                @include font-size(12, 18, 400);
            }
        }
    
        .--text-s {
            color: $gray-600;
        }

        h1 {
            margin-bottom: 16px;
            color: $gray-800;
            max-width: 936px;
        }
    }

    &_subtitle {
        margin-top: 24px;
        margin-bottom: 32px;
    }

    &_img {
        margin-bottom: 16px;
    }

    &_content {
        margin-top: 80px;
        max-width: 816px;
        align-self: center;

        img {
            width: 100%;
            height: auto;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 20px;
        }
    }

    @include rwd(800) {
        padding-top: 40px;
        padding-bottom: 40px;

        &_top {
            padding-bottom: 16px;

            &_tag {
                margin-bottom: 16px;
            }
        }

        &_subtitle {
            margin-top: 16px;
            margin-bottom: 40px;
        }

        &_content {
            margin-top: 40px;
        }
    }
}