.banner-box-with-background {
    padding-top: 24px;

    .banner-image {
        overflow: hidden;
        height: 376px;
        img {
            width: 100%;
        }
    }
    .box-with-background_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: stretch;
        gap: 24px;

        .--accessories {
            a {
                display: block;
                height: 100%;

                .--accessory {
                    height: 100%;
                }
            }
        }

        @include device-lg() {
            grid-template-columns: 1fr;
        }
    }
}