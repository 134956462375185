.background-black .gallery-with-text {
    .tabs {
        &-list {
            &__item {
                &.active {
                    border-color: $white;
                }
            }
        }
    }
}

.gallery-with-text {
    overflow: hidden;
    padding-right: 0;
    
    .tabs {
        &-list {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 40px;
            align-items: center;

            @include device-sm {
                justify-content: space-between;
                gap: 8px;
            }

            width: 100%;
            // Oculta scrollbar
            overflow: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
                width: 0 !important;
            }
    
            &__item {
                display: inline-block;
                padding: 24px 0px;
                flex-shrink: 0;
                text-transform: uppercase;
                min-width: 162px;
                &.active {
                    border-bottom: 2px solid $black;
                    font-weight: 700;
                    z-index: 1;
                }
            }
        
            text-align: center;
            @include font-size(16, 22, 700);
        }

        &-buttons {
            position: relative;
            // width: 100%;
            margin-bottom: 40px;
            margin-right: 80px;
    
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                border-bottom: 2px solid #B9C0C2;
                width: 100%;
            }

            @include device-lg {
                margin-right: 32px;
            }
        }

        &-panel {
            display: none;
            flex-direction: column;
            gap: 32px;
            animation: contentEffect $timming;
        }

        &-content {
            
            .swiper {
                width: 100%;

                .container_pag_nav1, .container_pag_nav2, .container_pag_nav3 {
                    margin-top: 14px;
                    margin-bottom: 20px;
                    display: flex;
                    position: relative;
                    width: 115px;
                    align-items: center;
                    justify-content: space-between;
                    font-family: 'Nobel', Arial, Helvetica, sans-serif;
                    @include font-size(18, 28, 400);
                    
                    .swiper-button-next, .swiper-button-prev {
                        color: $black;
                        width: 10.03px;
                        height: 5.45px;
                        margin: 0;
                        position: unset;
                    }
            
                    .swiper-button-next:after, .swiper-button-prev:after {
                        font-size: 12px;
                    }
                }

                &-slide {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .block {
                        width: 220px;
                        margin-top: 63px;
                    }

                    img {
                        width: calc(100% - 293px);
                        object-fit: cover;
                    }
                }
            }
        }
    }

    @include device-md {
        padding-left: 0;

        .tabs-buttons {
            display: none;
        }

        .tabs-content {
            .swiper  {
                &-slide {
                    flex-direction: column-reverse;
                    align-items: center;
                    justify-content: center;
                    gap: 24px;
                    
                    .block {
                        width: 100%;
                        margin-top: 0;
                        padding-left: 24px;
                        padding-right: 24px;
                    }
        
                    img {
                        width: 100%;
                    }
                }

                .container_pag_nav1, .container_pag_nav2, .container_pag_nav3 {
                    margin-left: 24px;
                    margin-right: 24px;
                    width: 105px;
                }
            }
        }
    }
}

.gallery-with-text-filters-mobile, .color-selector-filters-mobile {
    display: none;
    width: 100%;

    @include rwd(800) {
        display: block;
    }

    &__button {
        @include font-size(12, 24, 400, 7.5);
        text-transform: uppercase;
        display: flex;
        padding: 0;
        margin-bottom: 53px;
        // margin-right: auto;
        margin-left: auto;

        svg {
            margin-left: 4px;
        }
    }

    .gallery-with-text-filters-mobile__modal, .color-selector-filters-mobile__modal {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.5);
        z-index: 10;
        display: none;

        &__content {
            background-color: $white;
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 34px 24px 62px 24px;
            border-radius: 16px 16px 0px 0px;
            z-index: 11;
            width: 100vw;
            text-transform: uppercase;
            overflow-y: scroll;
            @include custom-scrollbars;

            .modal-close {
                display: flex;
                justify-content: space-between;
                margin-bottom: 18px;
            }

            .gallery-with-text-tabs-list_mobile, .color-selector-tabs-list_mobile {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@keyframes contentEffect {
    from {
        opacity: 0;
        transform: translateY(70%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}