.video-container {
    max-width: 1440px;
    max-height: 625px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-bottom: 60px;
    margin-top: 60px;

    .video {
        width: 100%;
        max-width: 1440px;
        height: 625px;
        object-fit: cover;
        position: relative;
        top: 0;
   
        &-thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 1440px;
            height: 625px;
            object-fit: cover;
            
        }
        
        &-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            // &.--play {
            //     display: none;
            // }
        }

    }

    @include device-md {
        max-height: 465px;

        .video {
            height: 465px;

            &-thumbnail {
                height: 465px;
            }
        }
    }
}