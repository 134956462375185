.paragraph-small-img {
    padding-top: 60px;
    overflow: hidden;
    
    p {
        margin-bottom: 20px;
    }
    
    &_container {
        @include main-grid;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 120px;
        
        &_text {
            grid-column: 1 / span 14;
            
            h3 {
                margin-bottom: 24px;
                text-transform: uppercase;
                @include font-size(20, 44, 400, 5);
            }

            a, button {
                margin-top: 32px;
            }
        }
        &_img {
            grid-column: 16 / -1;
            max-height: 557px;
        }

        @include device-lg {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            &_img {
                width: 40%;
                margin-left: 12px;
            }
        }

        @include rwd(800) {
            &_img {
                margin-left: 0px;
            }
        }
    }

    &_divider {
        width: 100%;
        display: block;
        border-bottom: 1px solid black;
    }
    @include rwd(800) {
        padding-top: 30px;
        padding-bottom: 30px;
        &_container {
            display: flex;
            flex-direction: column-reverse;
            background-image: none !important;
            &_text {
                width: 100%;
                h3 {
                    margin-top: 40px;
                }
            }
            &_img {
                width: 100%;
            }
        }
    }
}