.data_sheet {
    background-color: $black;

    &_content_grid {
        color: $white;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 80px;
        row-gap: 40px;
        border-top: 2px solid #454545;
        padding-top: 43px;

        h5 {
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 8px;
        }

        &_box {
            div {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    img {
        display: block;
        width: 100%;
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
        margin-top: -90px;
    }

    @include device-sm {
        margin-top: 0;
    }

    @include rwd(1200) {
        &_content_grid {
            grid-template-columns: 1fr;
            grid-template-rows: auto;

            &_box {
                display: none;
            }
    
            &_box:nth-child(1) {
                display: block;
            }
    
            &_box:nth-child(2) {
                display: block;
            }
        }
    }
}