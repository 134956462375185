.banner_black_box {
    position: relative;
    height: 705px;
    width: 100%;
    background-size: cover;
    background-position: center;
    max-width: 1440px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &_content {
        position: absolute;
        right: 40px;
        top: 7%;
        bottom: 7%;
        width: 550px;
        height: 86%;
        opacity: 0.8;
        background-color: $black;
        color: $white;
        display: flex;

        &_text {
            margin-left: 53px;
            margin-right: 107px;
            margin-top: auto;
            margin-bottom: auto;

            h1 {
                margin-bottom: 24px;
            }

            p {
                padding-top: 24px;
                border-top: 1px solid $white;
                margin-bottom: 24px;
                width: 100%;
                font-weight: 300;
            }
        }
    }

    @include device-md {
        height: 464px;

        &_content {
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            bottom: 0;

            &_text {
                margin-left: 24px;
                margin-right: 24px;
            }
        }
    }
}