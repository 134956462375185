.background-black .big-photo-and-description-somos-lexus {
    &_element-text{
        .line {
            border-color: $white;
        }
    }
}

.big-photo-and-description-somos-lexus {
    padding-bottom: 40px;

    &_element {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 80px;
        position: relative;
        gap: 30px;

        .img-container {
            width: calc(100% - 352px);
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 426px;
        }

        .video-container {
            margin: 0;
            .video, .video-thumbnail {
                height: 426px;
                max-width: 816px;
                object-fit: cover;
            }
        }

        &-img__mobile {
            display: none;
        }

        &-text {
            display: flex;
            flex-direction: column;
            width: 360px;

            .title {
                text-transform: uppercase;
                margin-bottom: 16px;
                @include font-size(20, 44, 700, 5);
            }

            .line {
                border-left: 1px solid $black;
                height: 70px;
            }

            .description {
                margin-top: 16px;
                margin-bottom: 24px;

                p {
                    margin-bottom: 20px;
                }
            }

            .tag_container {
                display: flex;
                align-items: center;

                .tag {
                    width: 24px;
                    height: 24px;
                    max-width: 24px;
                    max-height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                    border-radius: 50%;
                    margin-right: 8px;
                    margin-left: 16px;
                }

                .tag:first-of-type {
                    margin-left: 0;
                }
            }
        }

        .wrapper {
            padding-top: 0;
        }

        &.right {
            flex-direction: row;
        }

        &.left {
            flex-direction: row-reverse;
        }
    }

    @include rwd(800) {
        &_element {
            &-text {
                width: 300px;
            }

            .img-container {
                width: calc(100% - 300px);
            }
        }
    }

    @include rwd(650) {

        &_element {
            flex-direction: column-reverse !important;
            margin-bottom: 24px;

            .img-container {
                display: none;
            }

            .video-container {
                margin: 0;
                .video, .video-thumbnail {
                    height: 258px;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &-img__mobile {
                width: 100%;
                display: block;
            }

            &-text {
                width: 100%;
            }
        }
    }
}
